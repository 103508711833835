import { prop } from 'ramda';

import { get } from 'utils/http';
import { buildUrl } from 'utils/route';

import { URLS } from 'configuration';

import {
  FETCH_USER_DIETS_LIST_REQUEST,
  FETCH_USER_DIETS_LIST_FAILURE,
  FETCH_USER_DIETS_LIST_SUCCESS,
  FETCH_USER_DIET_CALENDAR_REQUEST,
  FETCH_USER_DIET_CALENDAR_SUCCESS,
  FETCH_USER_DIET_CALENDAR_FAILURE,
  SET_SELECTED_DIET,
  SET_VISIBLE_CALENDAR_DAYS,
  SET_SELECTED_DAY,
} from './const';
import {
  isDietAlreadySelected,
  transformDietsList,
  transformUserDietCalendar,
} from '../services/userDietRepresenter';

export const getUserDietsList = dietId => async (dispatch, getState) => {
  dispatch({
    type: FETCH_USER_DIETS_LIST_REQUEST,
  });

  try {
    const { data } = await get(
      URLS.USER_DIETS_LIST,
      { pagination: false },
      true
    );
    const diets = data.filter(({ shop }) => !shop);
    const [firstDiet] = diets;

    const dietAlreadySelected = isDietAlreadySelected(getState());

    if (dietId) {
      const selectedDiet = diets.find(diet => diet.id === parseInt(dietId));

      dispatch({
        type: SET_SELECTED_DIET,
        selectedDiet: selectedDiet.id,
        selectedDietObject: selectedDiet,
      });
    } else if (!dietAlreadySelected && firstDiet) {
      dispatch({
        type: SET_SELECTED_DIET,
        selectedDiet: prop('id', firstDiet),
        selectedDietObject: firstDiet,
      });
    }

    dispatch({
      type: FETCH_USER_DIETS_LIST_SUCCESS,
      list: transformDietsList(diets),
    });
  } catch (error) {
    dispatch({
      type: FETCH_USER_DIETS_LIST_FAILURE,
      error,
    });
  }
};

export const getUserDietCalendar = ({
  dietId,
  dateFrom,
  dateTo,
}) => dispatch => {
  dispatch({
    type: FETCH_USER_DIET_CALENDAR_REQUEST,
  });

  return get(
    buildUrl(URLS.GET_CALENDAR, {
      dietId,
      dateFrom,
      dateTo,
    })
  )
    .then(({ data }) => {
      return dispatch({
        type: FETCH_USER_DIET_CALENDAR_SUCCESS,
        calendar: transformUserDietCalendar(data),
      });
    })
    .catch(error =>
      dispatch({
        type: FETCH_USER_DIET_CALENDAR_FAILURE,
        error,
      })
    );
};

export const setSelectedDiet = (selectedDiet, selectedDietObject) => ({
  type: SET_SELECTED_DIET,
  selectedDiet: +selectedDiet,
  selectedDietObject,
});

export const setSelectedDay = selectedDay => ({
  type: SET_SELECTED_DAY,
  selectedDay,
});

export const setVisibleCalendarDays = visibleCalendarDays => ({
  type: SET_VISIBLE_CALENDAR_DAYS,
  visibleCalendarDays,
});
