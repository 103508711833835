import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { differenceInMinutes } from 'date-fns';
import { Helmet } from 'react-helmet';
import { BASE_URL, URLS } from 'configuration';
import routeURLS from 'routes/routeUrls';
import { compose } from 'redux';
import PwaReloadModal from 'common/components/PwaReloadModal';

import { getBrand, getConfig } from './actions/appActions';
import {
  refreshLogin,
  logout,
  updateUserTokens,
} from '../Auth/actions/authActions';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';

import { setPaymentMethod } from '../NewOrder/actions/orderFormActions';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from 'themes/main';

import AppRouter from 'routes/router';
import Global from 'styledComponents/Global';

import {
  createGTM,
  pushGTMPageViewEvent,
  pushAuthenticationEvent,
  flushDataLayer,
} from 'utils/gtm';
import { pushAuthenticationPollsterEvent } from 'utils/pollsterTrack';
import { initFacebookPixel } from 'utils/facebookPixel';
import { initGoogleAnalytics, pushGAPageViewEvent } from 'utils/ga';
import history from 'history.js';
import CookiesInfo from 'common/components/Cookies/CookiesInfo';
import CustomScriptsLoader from './CustomScriptsLoader';
import HotjarLoader from './HotjarLoader';
import SalesManagoLoader from './SalesManagoLoader';
import * as WebFont from 'webfontloader';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import routeUrls from 'routes/routeUrls';
import { http, post } from 'utils/http';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import Cookies from 'cookies-js';
import {
  clearAllSiteData,
  loadFontToDocumentHead,
  slugify,
} from 'utils/common';
import ConnectionProblemComponent from './ConnectionProblemComponent';
import HTTP_CODES from 'common/shared/httpCodes';
import { setMyLeadTrackingParams } from 'common/services/myLeadHelpers';
import showToast from 'utils/toast';
import * as Sentry from '@sentry/browser';
import { setNativeAppConfig } from 'views/App/actions/nativeAppActions';
import {
  setCounterPwaModal,
  incrementCounterPwaModal,
} from 'views/App/actions/appGlobalActions';

const Icon = ({ url }) => {
  var head = document.getElementsByTagName('head')[0];
  let linkEl = document.createElement('link');
  linkEl.type = 'image/x-icon';
  linkEl.rel = 'icon';
  linkEl.href = url;

  head.appendChild(linkEl);

  return null;
};

const isPWA = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://');

class App extends Component {
  state = {
    isOnline: true,
    isLoading: true,
    clientFont: 'Nunito Sans',
    pwaModalReloadOpened: false,
    offlineOrAPIDown: false,
  };

  _counter = createRef(0);

  async componentDidMount() {
    const {
      token,
      getBrand,
      getConfig,
      refreshLogin,
      getUserData,
      logout,
      setPaymentMethod,
    } = this.props;

    if (this.isLogoutNecessary()) {
      return logout().then(() => {
        window.location.href = routeUrls.NEW_ORDER_FORM;
      });
    }

    http.interceptors.response.use(
      this.onResponseFulfilled,
      this.onResponseRejected
    );
    // config object is required for ios PWA
    serviceWorkerRegistration.register(
      {
        onUpdate: () => {
          Sentry.setContext('service-worker', {
            onUpdate: true,
          });

          console.log('serviceWorkerRegistration onUpdate');
          this.setState({ pwaModalReloadOpened: true });
          this.props.incrementCounterPwaModal();
        },
      },
      callingBy => {
        Sentry.setContext('service-worker', {
          registerOpenModal: true,
          callingBy,
        });

        console.log('serviceWorkerRegistration callingBy');
        this.setState({ pwaModalReloadOpened: true });
        this.props.incrementCounterPwaModal();
      }
    );

    if (token) {
      try {
        await refreshLogin();
        await getUserData();
      } catch (e) {}
    }

    const searchParams = new URLSearchParams(window.location.search);

    const myLeadUserId = searchParams.get('mylead_user_id');
    const myLeadClickId = searchParams.get('mylead_click_id');

    if (myLeadUserId && myLeadClickId) {
      setMyLeadTrackingParams({ clickId: myLeadClickId, userId: myLeadUserId });
    }

    const disableThirdPartyLoginMethods = searchParams.get(
      'disableThirdPartyLoginMethods'
    );
    const disableThirdPartyLoginMethodsBool =
      disableThirdPartyLoginMethods === '1' ? true : false;

    const useNativeBridge = searchParams.get('useNativeBridge');
    const useNativeBridgeBool = useNativeBridge === '1' ? true : false;

    const disableCookiesPopup = searchParams.get('disableCookiesPopup');
    const disableCookiesPopupBool = disableCookiesPopup === '1' ? true : false;

    const disableTracking = searchParams.get('disableTracking');
    const disableTrackingBool = disableTracking === '1' ? true : false;

    const resetNativeConfig = searchParams.get('resetNativeConfig');
    const resetNativeConfigBool = resetNativeConfig === '1' ? true : false;

    this.props.setNativeAppConfig({
      ...(disableThirdPartyLoginMethods
        ? { disableThirdPartyLoginMethods: disableThirdPartyLoginMethodsBool }
        : {}),
      ...(useNativeBridge ? { useNativeBridge: useNativeBridgeBool } : {}),
      ...(disableCookiesPopup
        ? { disableCookiesPopup: disableCookiesPopupBool }
        : {}),
      ...(disableTracking ? { disableTracking: disableTrackingBool } : {}),
      ...(resetNativeConfig
        ? { resetNativeConfig: resetNativeConfigBool }
        : {}),
    });
    console.log('GET CONFIG');
    Promise.all([getBrand(), getConfig()]).then(() => {
      this.setState({ isLoading: false });
      this.initializeGTM();
      this.initializeGA();
      this.setPageViews();
      this.initializeFacebookPixel();
      this.initializeFonts();
      const {
        defaultPaymentMode,
        oneTimePaymentDefaultGateway,
        subscriptionPaymentDefaultGateway,
      } = this.props.ConfigClientPanel;
      const defaultGateway =
        defaultPaymentMode === '1'
          ? subscriptionPaymentDefaultGateway
          : oneTimePaymentDefaultGateway;
      setPaymentMethod(defaultGateway);

      const lang = localStorage.getItem('language') ?? null;
      const {
        defaultLanguage,
        clientAllowedToChangeLanguage,
        history,
      } = this.props;

      if (
        (clientAllowedToChangeLanguage && lang === null) ||
        !clientAllowedToChangeLanguage
      ) {
        moment.locale(defaultLanguage);
        localStorage.setItem('language', defaultLanguage);
        document.documentElement.lang = defaultLanguage;

        if (history.location.pathname.includes(routeURLS.NEW_ORDER_FORM)) {
          console.log('history push 1');
          history.push({
            pathname: `/${defaultLanguage}${routeURLS.NEW_ORDER_FORM}`,
            search: history.location.search,
          });
        }
      }
    });

    setInterval(this.setTime, 6000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.offlineOrAPIDown === false &&
      this.state.offlineOrAPIDown === true
    ) {
      console.log('update offlineOrAPIDown');
      // clearAllSiteData();
    }

    if (
      prevProps.pwaModalCounter !== this.props.pwaModalCounter &&
      this.props.pwaModalCounter >= 3
    ) {
      console.log('didUpdate run');
      clearAllSiteData();
      this.props.setCounterPwaModal(0);
    }
  }

  onResponseRejected = error => {
    console.log(error.response);
    if (!error.response) {
      this.setState({ offlineOrAPIDown: true }); // of no use for request sent outside of App component
      return;
    }

    const { status } = error.response;

    switch (status) {
      case HTTP_CODES.UNAUTHORIZED:
        const pathname = window.location.pathname;
        const languagePrefix = this.props.i18n.language;
        const pageToReturn = { from: { pathname } };

        if (!window.location.href.includes('auth/login')) {
          if (!error.config.url.includes('/refresh_token')) {
            const originalRequest = error.config;

            if (!originalRequest._retry) {
              originalRequest._retry = true;
              return post('/refresh_token', {
                refreshToken: this.props.refreshToken,
              }).then(
                ({ data: { token, refreshToken }, data }) => {
                  localStorage.setItem('t', token);
                  localStorage.setItem('rt', refreshToken);
                  originalRequest.headers['Authorization'] = `Bearer ${token}`;
                  this.props.updateUserTokens(data);

                  return http(originalRequest);
                },
                () => {
                  console.log('history push 2');

                  history.push(
                    `/${languagePrefix}${URLS.AUTH_LOGIN}`,
                    pageToReturn
                  );
                }
              );
            }
          } else {
            console.log('history push 3');

            history.push(`/${languagePrefix}${URLS.AUTH_LOGIN}`, pageToReturn);
          }
        }
        break;
      case HTTP_CODES.INTERNAL_SERVER_ERROR:
        showToast({
          message: window.t(
            '$*error.serverErrorOccured',
            'Wystąpił błąd serwera, prosimy spróbować ponownie później'
          ),
        });
        break;
      default:
        break;
    }

    return Promise.reject(error);
  };

  onResponseFulfilled = response => {
    // the code below checks version of app and refreshes without cache if app version under key x-cv is different
    const appvCookie = Cookies.get('appv');

    if (appvCookie !== response.headers['x-cv']) {
      if (appvCookie?.length > 0) {
        Sentry.setContext('service-worker', {
          appvCookie,
          responseXCV: response.headers['x-cv'],
        });

        console.log('onResponseFulfilled appvCookie');
        this.setState({ pwaModalReloadOpened: true });
        this.props.incrementCounterPwaModal();
      }

      Cookies.set('appv', response.headers['x-cv']);
    }

    return response;
  };

  initializeFonts = () => {
    const {
      Fonts: { clientText },
    } = this.props;

    const clientFontLibrary = clientText?.library || 'google';
    const clientFontLibraryParams = clientText?.libraryParams || {};
    const clientFont = clientText?.font || 'Nunito Sans';

    let webFontObject = {
      [clientFontLibrary]: {
        families: [clientFont],
      },
    };

    if (clientFontLibrary === 'typekit') {
      webFontObject = {
        typekit: clientFontLibraryParams,
      };
    }

    if (clientFontLibrary === 'custom') {
      const slug = slugify(clientFont);
      const url = `${process.env.REACT_APP_API_ENDPOINT}/static/fonts/${slug}/${slug}.css`;
      loadFontToDocumentHead(url);
    }

    WebFont.load({
      ...webFontObject,
    });

    this.setState(prevState => ({ ...prevState, clientFont }));
  };

  isLogoutNecessary = () => {
    const { lastAction, token } = this.props;
    const currentTime = new Date();
    // logout an user after 15 minutes of inactivity
    const MINUTES_TO_LOGOUT = 15;
    const keepUserLoggedIn = localStorage.getItem('keepMeLoggedIn') === 'true';

    return (
      token &&
      !keepUserLoggedIn &&
      differenceInMinutes(currentTime, lastAction) >= MINUTES_TO_LOGOUT
    );
  };

  setTime = () => {
    const { logout } = this.props;
    const currentTime = new Date();

    if (this.isLogoutNecessary()) {
      clearInterval(this.setTime);

      return logout();
    }

    localStorage.setItem('currentTime', currentTime);
  };

  getCurrentStep(url) {
    return (
      url && url.search && url.search.split('&').find(i => i.includes('step'))
    );
  }

  setPageViews() {
    const {
      PollsterTrack,
      GoogleAnalytics,
      GoogleTagManager,
      humanReadableId,
      disableTracking,
    } = this.props;
    const link = `${history.location.pathname}${history.location.search}`;

    const newOrderURL = routeURLS.NEW_ORDER_FORM;
    const isNewOrderPage =
      history.location.pathname.indexOf(newOrderURL) != -1 ? true : null;
    if (PollsterTrack?.enabled && !disableTracking && humanReadableId) {
      pushAuthenticationPollsterEvent(humanReadableId);
    }

    if (GoogleTagManager.enabled && !disableTracking) {
      if (!isNewOrderPage) pushGTMPageViewEvent(link);
      if (humanReadableId) {
        pushAuthenticationEvent(humanReadableId);
      }
    }
    if (GoogleAnalytics.enabled && !disableTracking) {
      if (!isNewOrderPage) pushGAPageViewEvent(link);
    }
    this.setupHistoryListening();
  }

  setupHistoryListening() {
    const { disableTracking } = this.props;
    let currentPathname = `${history.location.pathname}${history.location.search}`;
    let currentStep = this.getCurrentStep(history.location);
    let allow = true; //add throttle to prevent adding route changes at /new-order/create route

    history.listen(his => {
      const newOrderURL = routeURLS.NEW_ORDER_FORM;
      const isNewOrderPage =
        his.pathname.indexOf(newOrderURL) != -1 ? true : null;
      const wasNewOrderPage =
        currentPathname.indexOf(newOrderURL) != -1 ? true : null;

      const isCleanNewOrderPage =
        isNewOrderPage && history.location.search.length === 0;

      const isChangeWithinNewOrder =
        isNewOrderPage && wasNewOrderPage && !isCleanNewOrderPage;

      const currentLang = localStorage.getItem('i18nextLng');

      const hasRouteChanged =
        ((!isChangeWithinNewOrder &&
          his.pathname.replace(`/${currentLang}/`, '') !==
            currentPathname.replace(`/${currentLang}/`, '')) ||
          isCleanNewOrderPage) &&
        allow &&
        this.getCurrentStep(his) !== currentStep;

      if (hasRouteChanged && !disableTracking) {
        pushGTMPageViewEvent(`${his.pathname}${his.search}`);
        pushGAPageViewEvent(`${his.pathname}${his.search}`);
      }
      if (isCleanNewOrderPage && !disableTracking) flushDataLayer();
      allow = false;
      currentPathname = `${his.pathname}${his.search}`;
      currentStep = this.getCurrentStep(his);

      setTimeout(() => {
        allow = true;
      }, 500);
    });
  }

  componentWillUnmount() {
    clearInterval(this.setTime);
  }

  initializeGTM = () => {
    const { GoogleTagManager, disableTracking } = this.props;

    if (!GoogleTagManager.enabled && !disableTracking) {
      return;
    }

    createGTM(GoogleTagManager.code);
  };

  initializeGA = () => {
    const { GoogleAnalytics, disableTracking } = this.props;

    if (!GoogleAnalytics.enabled && !disableTracking) {
      return;
    }

    initGoogleAnalytics(GoogleAnalytics.code);
  };

  getRobotsContent = () => {
    const { noIndex, noFollow, noSnippet } = this.props.brand.seo;
    const content = [
      ...(noIndex ? ['noindex'] : []),
      ...(noFollow ? ['nofollow'] : []),
      ...(noSnippet ? ['nosnippet'] : []),
    ].join(', ');

    return content;
  };

  initializeFacebookPixel = () => {
    const { FacebookPixel, disableTracking } = this.props;

    if (!FacebookPixel.enabled && !disableTracking) {
      return;
    }

    initFacebookPixel(FacebookPixel.id);
  };

  render() {
    const {
      isLoading,
      brand,
      t,
      showMenuPage,
      PayU,
      PollsterTrack,
      disableTracking,
    } = this.props;
    const { seo } = brand;
    const { clientFont, offlineOrAPIDown } = this.state;
    const themeColor = brand.mainColor;
    const iconUrl = `${process.env.REACT_APP_API_ENDPOINT}${brand.brandFavicon}`;
    if (offlineOrAPIDown) {
      return <ConnectionProblemComponent />;
    }
    if (this.state.isLoading || isLoading) {
      return null;
    }
    return !offlineOrAPIDown ? (
      <>
        <Helmet>
          <title>
            {seo.metaTitle ||
              `${brand.name} - ${t('$*page.title', '$$Panel klienta')}`}
          </title>

          {/* Google / Search Engine Tags */}
          {seo.metaTitle && <meta itemprop="name" content={seo.metaTitle} />}
          {seo.image && (
            <meta
              itemprop="image"
              content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${seo.image?.contentUrl}`}
            />
          )}

          {(seo.noIndex || seo.noFollow || seo.noSnippet) && (
            <meta name="robots" content={this.getRobotsContent()} />
          )}
          {seo.metaDescription && (
            <meta name="description" content={seo.metaDescription} />
          )}
          {seo.metaDescription && (
            <meta itemprop="description" content={seo.metaDescription} />
          )}
          {seo.metaKeywords && (
            <meta name="keywords" content={seo.metaKeywords.toString()} />
          )}

          {/* Facebook Meta Tags */}
          <meta
            property="og:url"
            content={`${window.location.protocol}//${window.location.hostname}/`}
          />
          <meta property="og:type" content="website" />
          {seo.openGraphTitle && (
            <meta property="og:title" content={seo.openGraphTitle} />
          )}
          {seo.openGraphDescription && (
            <meta
              property="og:description"
              content={seo.openGraphDescription}
            />
          )}
          {seo.image && (
            <meta
              property="og:image"
              content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${seo.image?.contentUrl}`}
            />
          )}

          {/* Twitter Meta Tags */}
          <meta
            property="twitter:url"
            content={`${window.location.protocol}//${window.location.hostname}/`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {seo.openGraphTitle && (
            <meta name="twitter:title" content={seo.openGraphTitle} />
          )}
          {seo.openGraphDescription && (
            <meta
              name="twitter:description"
              content={seo.openGraphDescription}
            />
          )}
          {seo.image && (
            <meta
              name="twitter:image"
              content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${seo.image?.contentUrl}`}
            />
          )}
          {PayU?.enabled &&
            PayU?.cardPayments &&
            (PayU?.sandbox ? (
              <script
                type="text/javascript"
                src="https://secure.snd.payu.com/javascript/sdk"
              ></script>
            ) : (
              <script
                type="text/javascript"
                src="https://secure.payu.com/javascript/sdk"
              ></script>
            ))}
          {PollsterTrack?.enabled && (
            <script
              type="text/javascript"
              src="//cdn2.pollster.pl/nw.js"
            ></script>
          )}
        </Helmet>
        <CustomScriptsLoader customScripts={brand.customScripts} />
        {this.props.HotJar.enabled && !disableTracking && (
          <HotjarLoader {...this.props.HotJar} />
        )}
        {this.props.SalesManago.enabled && !disableTracking && (
          <SalesManagoLoader {...this.props.SalesManago} />
        )}
        <ThemeProvider theme={{ ...mainTheme, baseFontColor: themeColor }}>
          <PwaReloadModal
            testText={this.state.testText}
            isOpened={this.state.pwaModalReloadOpened}
          />
          <React.Fragment>
            <Icon url={iconUrl} />
            <Global font={clientFont} />
            <AppRouter showMenuPage={showMenuPage} />
          </React.Fragment>
        </ThemeProvider>
        {!this.props.disableCookiesPopup && (
          <CookiesInfo link={brand.privacyPolicyLink} themeColor={themeColor} />
        )}
      </>
    ) : (
      <ConnectionProblemComponent />
    );
  }
}

const enhance = compose(
  withTranslation(),
  withRouter,
  connect(
    ({
      app: {
        isLoading,
        brand,
        config: { modules, multinational },
      },
      appGlobal: { pwaModalCounter },
      nativeAppConfig: { disableCookiesPopup, disableTracking },
      auth: { token, lastAction, refreshToken, user: { humanReadableId } = {} },
    }) => ({
      refreshToken,
      isLoading,
      brand,
      token,
      lastAction,
      humanReadableId,
      PayU: modules?.PayU ?? {},
      ConfigClientPanel: modules?.ConfigClientPanel ?? {},
      GoogleAnalytics: modules?.GoogleAnalytics ?? {},
      GoogleTagManager: modules?.GoogleTagManager ?? {},
      PollsterTrack: modules?.PollsterTrack ?? {},
      FacebookPixel: modules?.FacebookPixel || {},
      HotJar: modules?.HotJar || {},
      SalesManago: modules?.SalesManago || {},
      Fonts: modules?.Fonts || {},
      defaultLanguage: multinational?.defaultLanguage,
      showMenuPage: modules?.MenuPage?.mode !== 'DISABLED',
      clientAllowedToChangeLanguage:
        multinational?.clientAllowedToChangeLanguage ?? false,
      disableCookiesPopup,
      disableTracking,
      pwaModalCounter,
    }),
    {
      getBrand,
      getConfig,
      setPaymentMethod,
      refreshLogin,
      getUserData,
      logout,
      updateUserTokens,
      setNativeAppConfig,
      setCounterPwaModal,
      incrementCounterPwaModal,
    }
  )
);

export default enhance(App);
