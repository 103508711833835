import React, { Fragment, useState, useEffect } from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import showToast from 'utils/toast';
import { prop } from 'ramda';
import MODALS from '../const/modal';
import MENU_PLANNING_MODALS from 'views/MenuPlanning/const/modal';

import { checkDietMissingStatus } from 'views/MenuPlanning/services/dietHelpers';
import {
  planMenu,
  planMenuIntent,
  checkBagNeedsPay,
  changeSubscriptionIntent,
  changeBag,
} from '../actions/menuPlanningActions';

import { Heading, Paragraph } from 'styledComponents/elements/Typography';
import Spacer from 'styledComponents/elements/Spacer';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';
import { NotificationWrapper } from 'styledComponents/elements/MenuPlanning/SingleDetailSection';
import SingleMealChooseSection from './SingleMealChooseSection/SingleMealChooseSection';
import PremiumBadge from './PremiumBadge';
import RenewDietBadge from './RenewDietBadge';
import PremiumActivationModal from './Modal/PremiumActivationModal';
import {
  DisplayOnDesktop,
  DisplayOnMobile,
} from '../../../styledComponents/helpers';
import { isAfter } from 'date-fns';
import MenuEditingPeriodInfo from './MenuEditingPeriodInfo';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { useTranslation } from 'react-i18next';
import { BackToCalendarLink } from './MenuPlanningLeftColumn/components';

const BadgeWrapper = styled.div`
  & > div ~ div {
    margin-top: 1.5rem;
  }
`;

const MealConfigurationSection = ({
  additionalPrices,
  allowChangeDiet,
  bag,
  changeBag,
  changeSubscriptionIntent,
  checkBagNeedsPay,
  getButtonAction,
  onBeforeBagSave,
  handleMenuPlanningContentFormModal,
  isEditable,
  meals,
  planMenu,
  planMenuIntent,
  premiumType,
  setDietsInWhichMealExists,
  calendar,
  selectedDay,
  isSubscriptionSuspended,
}) => {
  const { t } = useTranslation();
  const [modalOpened, toggleModal] = useState(false);
  const [premiumPrice, setPremiumPrice] = useState(null);
  const [needPayment, setNeedPayment] = useState(false);
  const [changeIsExpired, setChangeIsExpired] = useState(
    isAfter(new Date(), new Date(bag.changeDietDeadLine))
  );
  const isSubscriptionIntent =
    calendar?.days?.[selectedDay]?.['@type'] === 'SubscriptionIntent';

  useEffect(() => {
    const interval = setInterval(() => {
      const isExpired = isAfter(new Date(), new Date(bag.changeDietDeadLine));
      setChangeIsExpired(isExpired);
    }, 30000); // 30s

    return () => clearInterval(interval);
  }, []);

  const onSubmit = (newItems, { getState }) => {
    // getState - internal final form state
    const { dirtyFields } = getState();
    const changedItems = Object.keys(dirtyFields);

    if (isSubscriptionIntent) {
      planMenuIntent({
        newItems,
        changedItems,
        currentItems: bag.menuIntents,
      });
    } else {
      planMenu({ id: bag.id, newItems, changedItems, currentItems: bag.items });
    }
  };

  const handleModalOpen = async (modalId, existInDiets) => {
    //open modal if you have standard option only
    if (premiumType === 'PREMIUM') {
      const isPremium = bag.optionChangeMenu;
      !isPremium && toggleModal(MODALS.PREMIUM_ACTIVATION_MODAL);

      if (!isPremium) {
        if (isSubscriptionIntent) {
          const newParams = {
            dates: [selectedDay],
            optionChangeMenu: true,
          };
          changeSubscriptionIntent(newParams).catch(
            ({ response: { data } }) => {
              const price = data?.price;
              const newPrice = data?.newPrice;

              if (price && newPrice) {
                if (price.beforeDiscount !== newPrice.beforeDiscount) {
                  setNeedPayment(true);
                  setPremiumPrice(newPrice?.priceParts['CHANGE_MENU']);
                } else {
                  setNeedPayment(false);
                }
              } else {
                showToast({
                  message: 'Error',
                  type: 'error',
                });
              }
            }
          );
        } else {
          checkBagNeedsPay(
            { optionChangeMenu: true, changedAttributes: ['optionChangeMenu'] },
            bag
          ).then(
            ({ cost, needPayment }) => {
              if (needPayment) {
                setNeedPayment(true);
                setPremiumPrice(cost);
              } else {
                setNeedPayment(false);
              }
            },
            error =>
              error.response.data?.violations?.forEach(violation => {
                showToast({
                  message: violation.message,
                  type: 'error',
                });
              })
          );
        }
      }
    } else if (premiumType === 'STANDARD') {
      setDietsInWhichMealExists(existInDiets);
      handleMenuPlanningContentFormModal(
        MENU_PLANNING_MODALS.DIET_CHANGE_MODAL
      );
    } else {
      return null;
    }
  };

  const handlePremium = additionalParams => {
    if (isSubscriptionIntent) {
      return onBeforeBagSave({
        optionChangeMenu: true,
        dates: [selectedDay],
        ...additionalParams,
      });
    }

    return changeBag(
      { optionChangeMenu: true, changedAttributes: ['optionChangeMenu'] },
      bag,
      additionalParams
    ).then(response => {
      if (response) {
        const { order } = response;

        const paymentLink = prop('paymentLink', order);

        localStorage.setItem('dietDayToPay', window.location.pathname);

        if (paymentLink) {
          window.location.href = paymentLink;
        } else {
          window.location.reload();
        }
      }
    });
  };

  const getInitialValues = () => {
    const items = isSubscriptionIntent ? bag.menuIntents : bag.items;
    const initial = items.reduce((acc, item) => {
      return {
        ...acc,
        ...(item.dish
          ? { [item.mealType['@id']]: item.dish.id.toString() }
          : {}),
      };
    }, {});

    return initial;
  };

  const renderMeals = ({ formValues, meals }) => {
    const bagMeals = isSubscriptionIntent
      ? bag.menuIntents.some(menuIntent => menuIntent.dishes.length === 0)
      : bag.items.some(dish => !dish.dish);

    // TODO: on loading change translations on "wait is loading"
    if (meals.length === 0 || (meals.length > 0 && bagMeals)) {
      if (isSubscriptionSuspended) return null;
      return (
        <Paragraph>
          {t(
            '$*mealConfigurationSection.menuWillBeAvailableSoon',
            'Twoje menu będzie dostępne wkrótce'
          )}
        </Paragraph>
      );
    }

    const renderedMealsSections = meals.map(({ id, name, dishes }) => {
      const dishByMealType = isSubscriptionIntent
        ? bag.menuIntents.find(
            dish => dish.mealType['@id'] === `/meal-types/${id}`
          )
        : bag.items.find(dish => dish.mealType['@id'] === `/meal-types/${id}`);

      const hasDish = dishByMealType?.dish ?? false;

      return (
        hasDish && (
          <SingleMealChooseSection
            key={id}
            mealId={+id}
            mealName={name}
            mealIdString={`/meal-types/${id}`}
            dishes={dishes}
            toggleModal={handleModalOpen}
            modalId={MODALS.PREMIUM_ACTIVATION_MODAL}
            selectedDishes={isSubscriptionIntent ? bag.menuIntents : bag.items}
            isEditable={isEditable}
            formValues={formValues}
          />
        )
      );
    });

    return renderedMealsSections;
  };

  const isDietMissing = checkDietMissingStatus(bag);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues()}
      render={({ handleSubmit, submitting, pristine, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {isSubscriptionSuspended && <BackToCalendarLink />}
            {isSubscriptionIntent && isSubscriptionSuspended && (
              <RenewDietBadge
                suspend={isSubscriptionSuspended}
                onClickAction={getButtonAction(5)}
              />
            )}
          </div>
          <Flex
            style={{ marginTop: '40px' }}
            alignItems="center"
            justifyContent={['center', 'space-between']}
          >
            <Heading
              style={{
                alignSelf: 'flex-start',
                lineHeight: 1,
                marginBottom: '1rem',
              }}
              paddingMobile="0"
              noPadding
              textAlignMobile="center"
              textAlign="center"
            >
              {isSubscriptionSuspended
                ? t(
                    '$*mealConfigurationSection.heading.suspended',
                    'Menu dostępne po wznowieniu:'
                  )
                : t('$*mealConfigurationSection.heading', 'Twoja dostawa')}
              {t}
            </Heading>
            <DisplayOnDesktop
              style={{ alignSelf: 'baseline', marginTop: '0.5rem' }}
            >
              <BadgeWrapper>
                <Flex alignItems="center" justifyContent="space-between">
                  {premiumType === 'PREMIUM' && !isSubscriptionSuspended && (
                    <PremiumBadge
                      noDisplay={isDietMissing}
                      isPremium={prop('optionChangeMenu', bag)}
                      changeIsExpired={changeIsExpired}
                      handleModalOpen={handleModalOpen}
                    />
                  )}
                  {!isSubscriptionSuspended && (
                    <MenuEditingPeriodInfo
                      dueDate={bag.changeDietDeadLine}
                      changeIsExpired={changeIsExpired}
                    />
                  )}
                </Flex>
              </BadgeWrapper>
            </DisplayOnDesktop>
          </Flex>
          <Flex justifyContent={['center', 'center', 'space-between']}>
            <DisplayOnMobile>
              {premiumType === 'PREMIUM' && !isSubscriptionSuspended && (
                <PremiumBadge
                  style={{ marginLeft: 'auto' }}
                  noDisplay={isDietMissing}
                  isPremium={prop('optionChangeMenu', bag)}
                  changeIsExpired={isAfter(
                    new Date(),
                    new Date(bag.changeDietDeadLine)
                  )}
                  handleModalOpen={handleModalOpen}
                />
              )}
            </DisplayOnMobile>
          </Flex>
          <Spacer height="16px" />
          {bag.notifications.map(el => (
            <NotificationWrapper
              dangerouslySetInnerHTML={{ __html: el.content }}
            />
          ))}
          {renderMeals({ formValues: values, meals })}
          {
            <Fragment>
              <DisplayOnDesktop>
                <Flex>
                  <Box pt={[20, 20]} px={[0, 40]} pb={[20, 60]} width={1}>
                    {isEditable &&
                      meals.length > 0 &&
                      !isSubscriptionSuspended &&
                      (premiumType === 'PREMIUM'
                        ? prop('optionChangeMenu', bag)
                        : !!(
                            premiumType === 'STANDARD' && allowChangeDiet
                          )) && (
                        <Fragment>
                          <HorizontalLine marginBottom="32px" />
                          <Flex justifyContent="flex-end">
                            <Box>
                              {
                                <ButtonPrimary
                                  type="submit"
                                  uppercased
                                  disabled={
                                    submitting ||
                                    invalid ||
                                    pristine ||
                                    (premiumType === 'PREMIUM'
                                      ? !prop('optionChangeMenu', bag)
                                      : false)
                                  }
                                  weightBold
                                >
                                  {pristine
                                    ? t('$*common.noChanges', 'Brak zmian')
                                    : t(
                                        '$*common.saveChangesCaption',
                                        'Zapisz zmiany'
                                      )}
                                </ButtonPrimary>
                              }
                            </Box>
                          </Flex>
                        </Fragment>
                      )}
                  </Box>
                </Flex>
              </DisplayOnDesktop>
              <DisplayOnMobile
                style={{
                  position: 'sticky',
                  bottom: 0,
                }}
              >
                {isEditable &&
                  meals.length > 0 &&
                  !isSubscriptionSuspended &&
                  (premiumType === 'PREMIUM'
                    ? prop('optionChangeMenu', bag)
                    : !!(premiumType === 'STANDARD' && allowChangeDiet)) && (
                    <Box>
                      {
                        <ButtonPrimary
                          type="submit"
                          uppercased
                          disabled={
                            submitting ||
                            invalid ||
                            pristine ||
                            (premiumType === 'PREMIUM'
                              ? !prop('optionChangeMenu', bag)
                              : false)
                          }
                          weightBold
                          style={{ width: '100%' }}
                        >
                          {pristine
                            ? t('$*common.noChanges', 'Brak zmian')
                            : t('$*common.saveChangesCaption', 'Zapisz zmiany')}
                        </ButtonPrimary>
                      }
                    </Box>
                  )}
              </DisplayOnMobile>
            </Fragment>
          }
          <PremiumActivationModal
            id={MODALS.PREMIUM_ACTIVATION_MODAL}
            isOpened={modalOpened}
            needPayment={needPayment}
            toggleModal={() => toggleModal(null)}
            premiumPrice={premiumPrice}
            additionalPrices={additionalPrices}
            isSubscriptionIntent={isSubscriptionIntent}
            handlePremiumStatusChange={handlePremium}
          />
        </form>
      )}
    />
  );
};

export default connect(
  ({
    app: {
      brand: { premiumType, allowChangeDiet },
    },
    menuPlanning: { calendar, selectedDay },
    orderForm: {
      orderConfig: { additionalPrices },
    },
  }) => ({
    premiumType,
    additionalPrices,
    allowChangeDiet,
    calendar,
    selectedDay,
  }),
  {
    changeBag,
    changeSubscriptionIntent,
    checkBagNeedsPay,
    planMenu,
    planMenuIntent,
  }
)(MealConfigurationSection);
