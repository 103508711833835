export const TOGGLE_DAY = 'calendar/TOGGLE_DAY';
export const SET_DURATION = 'calendar/SET_DURATION';
export const SET_DAYS_RANGE = 'calendar/SET_DAYS_RANGE';
export const SET_INCLUDE_SATURDAYS = 'calendar/SET_INCLUDE_SATURDAYS';
export const SET_INCLUDE_SUNDAYS = 'calendar/SET_INCLUDE_SUNDAYS';
export const CLEAR_SELECTED_DAYS = 'calendar/CLEAR_SELECTED_DAYS';
export const INCREMENT_DAY_CLICKS = 'calendar/INCREMENT_DAY_CLICKS';
export const UNSELECT_SATURDAYS = 'calendar/UNSELECT_SATURDAYS';
export const UNSELECT_SUNDAYS = 'calendar/UNSELECT_SUNDAYS';
export const SET_SELECTED_DAYS = 'calendar/SET_SELECTED_DAYS';
