import React from 'react';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from 'configuration';
import { mainTheme } from 'themes/main';

import StyledLogo from 'styledComponents/elements/Logo';
// responsive images
const Logo = ({ logoLink, isSmall, onLoadLogo, logo }) => {
  return (
    <a href={logoLink}>
      <picture>
        <source
          media={`(max-width: ${mainTheme.deviceBreakpoints.mobile}px)`}
          srcSet={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.mobile?.contentUrl}`}
        />
        <source
          media={`(min-width: ${mainTheme.deviceBreakpoints.mobile + 1}px)`}
          srcSet={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.desktop?.contentUrl}`}
        />
        <StyledLogo
          src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.desktop?.contentUrl}`}
          alt="Logo"
          small={isSmall}
          onLoad={onLoadLogo}
        />
      </picture>
    </a>
  );
};

export default connect(
  ({
    app: {
      brand: { brandLogo, logoLink },
      config: { logo },
    },
  }) => ({
    brandLogo: `${BASE_URL}${brandLogo}`,
    logoLink,
    logo,
  })
)(Logo);
