import React, { Fragment, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import URLS from 'routes/routeUrls';
import { buildUrl } from 'utils/route';

import { media } from 'styledComponents/helpers';
import { translateErrorMessages } from 'common/shared/errorMessages';
import Tabs from 'common/components/Tabs';
import FormNavButtons from 'common/components/Form/FormNavButtons';
import { setNextTabDisability } from '../OrderFormTabs/actions/orderFormTabsActions';
import {
  getUserAddresses,
  clearUserAddressesFromLS,
  createAddress,
} from 'common/components/UserAddresses/actions/userAddressesActions';
import { setTabsCount, setTabIndex } from './actions/orderFormTabsActions';
import {
  createOrder,
  getOrderCost,
  userProceed,
} from '../../actions/orderFormActions';
import {
  updateForm,
  setDeliveryAddressFormId,
} from 'views/NewOrder/actions/dietFormActions';

import NEW_ORDER_MODALS from '../../const/modal';

import { addToCart, beginCheckoutGTM, pushGTMPageViewEvent } from 'utils/gtm';
import {
  addToCartGA,
  enhancedEcommerceAddProduct,
  enhancedEcommerceCheckout,
  pushGAPageViewEvent,
} from 'utils/ga';

import showToast from 'utils/toast';

import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { addToQueryString } from 'common/shared/querystring';
import { useTabsConfig } from 'views/NewOrder/components/OrderFormTabs/tabsConfig';
import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';
import { symbolToCurrency } from 'utils/currencies';
import withStripeConsumer from 'common/components/HOC/withStripeConsumer';

const StyledFormNavButtons = styled.div`
  margin-top: 50px;

  ${media.tabletDown`
    margin-top: 30px;
  `};
`;

const getStepName = (tabsConfig, tabIndex) => {
  if (!tabsConfig) return;

  const tabName = tabsConfig[tabIndex]?.step;

  if (!tabName) return;

  return tabName;
};

const getUserId = user => {
  let userId = '';
  if (user && user.id) {
    userId = +user.id.replace('/clients/', '');
  }
  return userId ? userId : 'notLoggedIn';
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

//Flag for button dissability
let disableOrderButton = false;

const OrderFormTabs = ({
  onChangePrice,
  values,
  isPrevDisabled,
  isNextDisabled,
  onPrevClick,
  onNextClick,
  tabIndex,
  setTabIndex,
  premiumType,
  orderForm,
  tabsCount,
  modules,
  tabName,
  disableTracking,
  ...rest
}) => {
  const { t } = useTranslation();
  const [modalOpened, handleModalOpen] = useState(null);
  const tabsConfig = useTabsConfig();

  const { currentFormId, forms } = useSelector(state => state.dietForm);
  const currentForm = forms?.[currentFormId] ?? {};
  const currentFormDeliveryDays = currentForm?.deliveryDays ?? [];
  const currentFormDietLength = currentForm?.dietLength ?? 0;
  const currentFormPaymentMode = currentForm?.paymentMode ?? '2';
  const currentFormHasValidDietLength =
    currentFormDeliveryDays?.length === currentFormDietLength;

  const getNxtButtonHintText = () => {
    if (
      tabIndex === 0 &&
      !currentFormHasValidDietLength &&
      currentFormPaymentMode === '2'
    ) {
      return t(
        '$*orderFormTabs.nextButtonHint.invalidCalendarDays',
        '$$Wybierz poprawną ilość dni na kalendarzu'
      );
    }

    if (
      tabIndex === 0 &&
      currentFormDeliveryDays?.length !== 1 &&
      currentFormPaymentMode === '1'
    ) {
      return t(
        '$*orderFormTabs.nextButtonHint.invalidFirstCalendarDay',
        '$$Wybierz pierwszy dzień dostawy'
      );
    }
  };

  const nextButtonHintText = getNxtButtonHintText();

  useEffect(() => {
    const { setTabsCount, validStepsIndexes, setNextTabDisability } = rest;

    validStepsIndexes.includes(tabIndex) && setNextTabDisability(false);
  }, []);

  useEffect(() => {
    const { setTabsCount } = rest;

    setTabsCount(tabsConfig.length);
  }, [tabsConfig]);

  useEffect(() => {
    const { user } = rest;
    addToQueryString('userId', getUserId(user));
    addToQueryString('step', getStepName(tabsConfig, tabIndex));

    if (!disableTracking) {
      pushGTMPageViewEvent();
      pushGAPageViewEvent();
    }
  }, [tabIndex, tabsConfig, disableTracking]);

  return (
    <Fragment>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={tabIndex => setTabIndex(tabIndex)}
        tabs={tabsConfig.map(cfg => cfg.label)}
        panels={tabsConfig.map(cfg => cfg.panel)}
        values={values}
        tabsCount={tabsCount}
        {...rest}
        id={NEW_ORDER_MODALS.PREMIUM_OPTION_MODAL}
        isOpened={modalOpened}
        toggleModal={() => handleModalOpen(null)}
        premiumPricePerDay={onChangePrice}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
      />
      <StyledFormNavButtons>
        <FormNavButtons
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          isOnLastStep={tabName === 'OrderFormSummary'}
          /* Hide next button on 4 step (when an anonymous user create diet) */
          isNextButtonVisible={
            rest.token === null
              ? tabIndex !== tabsCount - 1
              : tabIndex !== tabsCount
          }
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          isLoading={orderForm.isLoading}
          premiumModalOpen={handleModalOpen}
          premiumModalId={NEW_ORDER_MODALS.PREMIUM_OPTION_MODAL}
          nextButtonHintText={nextButtonHintText}
        />
      </StyledFormNavButtons>
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: { premiumType, premiumAdditionalStep },
        config: { modules, multinational },
      },
      nativeAppConfig: { disableTracking },
      auth: { token, user },
      dietForm: { paymentMode },
      orderFormTabs: {
        tabIndex,
        tabsCount,
        isPrevDisabled,
        isNextDisabled,
        validStepsIndexes,
        tabName,
      },
      userAddresses: { list, firstUserAddress },
      orderForm,
      dietForm,
    }) => ({
      orderForm,
      dietForm,
      token,
      user,
      tabIndex,
      tabsCount,
      tabName,
      isPrevDisabled,
      isNextDisabled,
      list,
      firstUserAddress,
      premiumType,
      premiumAdditionalStep,
      validStepsIndexes,
      modules,
      multinational,
      paymentMode,
      needStripePromise: true,
      disableTracking,
    }),
    {
      setTabIndex,
      setTabsCount,
      createOrder,
      getOrderCost,
      setNextTabDisability,
      getUserAddresses,
      updateForm,
      setDeliveryAddressFormId,
      clearUserAddressesFromLS,
      createAddress,
      getUserData,
      userProceed,
    }
  ),
  withRouter,
  withTranslation(),
  withStripeConsumer,
  withProps(
    ({
      history,
      token,
      tabsCount,
      tabIndex,
      tabName,
      setTabIndex,
      createOrder,
      orderForm,
      dietForm,
      getOrderCost,
      getUserAddresses,
      setDeliveryAddressFormId,
      clearUserAddressesFromLS,
      list,
      firstUserAddress,
      getUserData,
      setNextTabDisability,
      userProceed,
      form: { mutators },
      t,
      stripe,
      modules,
      multinational,
      stripeSecret,
      disableTracking,
    }) => ({
      onPrevClick: () => {
        setTabIndex(tabIndex - 1);

        return orderForm.userProceed ? window.scrollTo(0, 500) : scrollToTop();
      },
      onNextClick: async () => {
        token && getUserData();
        const { currentFormId } = dietForm;
        const { GoogleAnalytics, GoogleTagManager, FacebookPixel } = modules;

        // const suitableTabIndex =
        //   premiumType === 'PREMIUM' && premiumAdditionalStep ? 2 : 1;

        const isOrderFormDelivery = tabName === 'OrderFormDelivery';

        if (isOrderFormDelivery && !disableTracking) {
          if (GoogleTagManager.enabled) {
            addToCart(
              currentFormId,
              symbolToCurrency(multinational.currencySymbol)
            );
          }
          if (GoogleAnalytics.enabled) {
            if (GoogleAnalytics.useEcommerce) addToCartGA(currentFormId);
            enhancedEcommerceAddProduct(currentFormId);
          }
        }
        // If youe are on step 2 and adding first address
        if (isOrderFormDelivery && !list.length) {
          //If user is logged in, create new address on database
          //else, only read from local storage
          if (token) {
            createAddress(
              firstUserAddress,
              () =>
                getUserAddresses(token).then(addresses => {
                  const addressId = addresses[0].id.toString();
                  setDeliveryAddressFormId(currentFormId, addressId).then(
                    () => {
                      mutators.setFieldValue(
                        FORM_FIELD_NAMES.DELIVERY_ADDRESS,
                        addressId
                      );
                    }
                  );

                  getOrderCost();
                }),
              true
            );
          } else {
            getUserAddresses().then(addresses => {
              if (addresses.list[0]) {
                const addressId = addresses.list[0].id.toString();
                setDeliveryAddressFormId(currentFormId, addressId).then(() => {
                  mutators.setFieldValue(
                    FORM_FIELD_NAMES.DELIVERY_ADDRESS,
                    addressId
                  );
                });
              }

              getOrderCost();
            });
          }
        }

        if (tabsCount === tabIndex + 1) {
          if (disableOrderButton) {
            return;
          }

          setNextTabDisability(true);

          try {
            const { data: order } = await createOrder();

            const revenue =
              order.paymentType === 'STRIPE_CARD' && order.priceLeftToPay == 0
                ? order.pricePayed
                : order.priceLeftToPay;

            clearUserAddressesFromLS();
            disableOrderButton = false;
            if (FacebookPixel.enabled && !disableTracking) {
              ReactPixel.track('InitiateCheckout');
            }
            if (GoogleAnalytics.enabled && !disableTracking) {
              enhancedEcommerceCheckout(
                order.id,
                revenue,
                order.paymentType,
                order.discountCode
              );
            }
            if (GoogleTagManager.enabled && !disableTracking) {
              beginCheckoutGTM(order.id, revenue, order.discountCode);
            }

            if (order?.declineCode === 'generic_decline') {
              showToast({
                message: t('$*newOrder.confirmationFailed.title'),
                type: 'error',
              });
            }

            const orderId =
              order['@type'] === 'PaymentIntent' ? order.order.id : order.id;

            if (
              order['@type'] === 'PaymentIntent' &&
              order?.declineCode === 'authentication_required'
            ) {
              try {
                const {
                  error,
                  paymentIntent,
                } = await stripe.confirmCardPayment(order.clientSecret, {
                  payment_method: order.lastPaymentMethodId,
                });

                if (error) {
                  showToast({
                    message: t(
                      `$*stripe.error.${error.code}`,
                      `$$${error.message}`
                    ),
                    type: 'error',
                  });

                  if (
                    error?.decline_code === 'insufficient_funds' ||
                    error?.code === 'payment_intent_authentication_failure'
                  ) {
                    return history.push(URLS.NEW_ORDER_ERROR);
                  } else {
                    return history.push(
                      buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                        orderId,
                      })
                    );
                  }
                } else {
                  if (paymentIntent.status === 'succeeded') {
                    return history.push(
                      buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                        orderId,
                      })
                    );
                  }
                }
              } catch (error) {
                console.log({ error });
              }
            }

            if (order['@type'] === 'PaymentIntent') {
              return history.push(
                buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                  orderId,
                })
              );
            }

            if (order['@type'] === 'Order') {
              order.paymentLink
                ? (window.location.href = order.paymentLink)
                : history.push(
                    buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                      orderId,
                    })
                  );
            }
          } catch (error) {
            setNextTabDisability(false);
            disableOrderButton = false;
            showToast({
              message:
                error?.response?.data?.violations
                  ?.map(error => error.message)
                  .join('\n') ||
                translateErrorMessages(t).SERVER_ERROR_OCCURRED,
              type: 'error',
            });
          }
        } else {
          setTabIndex(tabIndex + 1);
          scrollToTop();
          !orderForm.userProceed && userProceed();
        }
      },

      onChangePrice: orderForm.orderConfig.additionalPrices.CHANGE_MENU,
      token,
      tabIndex,
    })
  )
)(OrderFormTabs);
