import { useState, useEffect } from 'react';

const CustomScriptsLoader = ({ customScripts }) => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  useEffect(() => {
    return setScriptsLoaded(true);
  }, []);

  let script = document.createElement('script');
  script.innerHTML = customScripts;
  !scriptsLoaded && document.head.appendChild(script);

  return null;
};

export default CustomScriptsLoader;
