import produce from 'immer';
import { format } from 'date-fns';

import {
  TOGGLE_PREMIUM,
  TOGGLE_ECOBOX,
  TOGGLE_SUBSCRIPTION_DELIVERY,
  FETCH_MEALS_REQUEST,
  FETCH_MEALS_SUCCESS,
  FETCH_MEALS_FAILURE,
  FETCH_BAG_SUCCESS,
  FETCH_BAG_FAILURE,
  FETCH_BAG_REQUEST,
  PLAN_MENU_REQUEST,
  PLAN_MENU_FAILURE,
  PLAN_MENU_SUCCESS,
  SET_SELECTED_DAY,
  SET_SELECTED_DIET,
  SET_VISIBLE_CALENDAR_DAYS,
  FETCH_USER_DIET_CALENDAR_SUCCESS,
  CLEAR_MEALS,
  RATE_MENU_REQUEST,
  RATE_MENU_SUCCESS,
  RATE_MENU_FAILURE,
  GET_DIET_DAYS_TO_CHANGE,
  SET_DIET_DAYS_TO_CHANGE,
  SET_INITIAL_DIET_DAYS_TO_CHANGE,
  SET_MENU_PLANNING_INITIAL_STATE,
  ADD_ADDONS,
  RESET_LOADING,
  ADD_LOADING,
  UPDATE_SELECTED_DAY_DETAILS,
  FETCH_DEFAULT_SUBSCRIPTION_SUCCESS,
  FETCH_DEFAULT_SUBSCRIPTION_REQUEST,
  FETCH_DEFAULT_SUBSCRIPTION_FAILURE,
  PUT_DEFAULT_SUBSCRIPTION_SUCCESS,
  PUT_DEFAULT_SUBSCRIPTION_REQUEST,
  PUT_DEFAULT_SUBSCRIPTION_FAILURE,
  CLEAR_DEFAULT_SUBSCRIPTION_DETAILS,
  SET_LAST_CHANGE_IN_DIET_DATA,
  SET_UNMOUNTED_PREPARING_MODAL_STATUS,
} from '../actions/const';

import { getLastDaysBeforeAndAfter } from '../services/calendarService';

import { TODAY } from 'common/shared/weekdays';
import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';
import { DAY_STATUSES } from '../../../common/components/BigCalendar/const/dayStatuses';

const INITIAL_STATE = {
  visibleCalendarDays: getLastDaysBeforeAndAfter(TODAY),
  selectedDiet: null,
  selectedDay: dateObjectToYearMonthDay(TODAY),

  calendar: {},
  meals: [],
  mealTypes: [],
  addons: [],
  variant: null,
  bag: null,

  isLoading: 0,
  error: null,

  isPremium: false,
  dietDays: [],
  dietDaysToChange: [],
  initialDietDaysToChange: [],
  deliveryDaysActiveTab: 0,
  defaultSubscriptionDietDetails: {},
  tmpChangeData: {},
  isUnmountedPreparingModal: false,
};

const menuPlanningReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TOGGLE_PREMIUM: {
        draft.bag.optionChangeMenu = !draft.bag.optionChangeMenu;
        break;
      }

      case TOGGLE_ECOBOX: {
        draft.bag.useEcoContainers = !draft.bag.useEcoContainers;
        break;
      }

      case TOGGLE_SUBSCRIPTION_DELIVERY: {
        const active = DAY_STATUSES.SUBSCRIPTION_CONFIGURABLE;
        const disabled = DAY_STATUSES.SUBSCRIPTION_DISABLED; //@todo do podmiany na nowe statusy
        const selectedDay = draft.calendar.days[draft.selectedDay];
        selectedDay.state = selectedDay.state === active ? disabled : active;
        selectedDay.newState = selectedDay.state === active ? disabled : active;
        break;
      }
      case UPDATE_SELECTED_DAY_DETAILS: {
        draft.bag = action.data;
        break;
      }
      case FETCH_BAG_REQUEST: {
        draft.isLoading = draft.isLoading + 2;
        break;
      }

      case FETCH_MEALS_REQUEST:
      case FETCH_DEFAULT_SUBSCRIPTION_REQUEST:
      case PUT_DEFAULT_SUBSCRIPTION_REQUEST:
      case PLAN_MENU_REQUEST:
      case RATE_MENU_REQUEST: {
        draft.isLoading = draft.isLoading + 1;
        break;
      }

      case FETCH_MEALS_FAILURE:
      case FETCH_BAG_FAILURE: {
        draft.isLoading = draft.isLoading - 2;
        draft.error = action.error;
        break;
      }

      case RATE_MENU_FAILURE:
      case FETCH_DEFAULT_SUBSCRIPTION_FAILURE:
      case PUT_DEFAULT_SUBSCRIPTION_FAILURE:
      case PLAN_MENU_FAILURE: {
        draft.isLoading = draft.isLoading - 1;
        draft.error = action.error;
        break;
      }

      case FETCH_USER_DIET_CALENDAR_SUCCESS: {
        draft.calendar = {
          ...action.calendar,
          days: {
            ...draft.calendar.days,
            ...action.calendar.days,
          },
        };
        break;
      }

      case FETCH_MEALS_SUCCESS: {
        draft.isLoading = draft.isLoading - 2;
        draft.meals = action.meals;
        break;
      }

      case FETCH_BAG_SUCCESS: {
        draft.isLoading = draft.isLoading - 1;
        draft.bag = action.bag;
        draft.mealTypes = action.mealTypes;
        draft.variant = action.variant;
        break;
      }

      case FETCH_DEFAULT_SUBSCRIPTION_SUCCESS:
      case PUT_DEFAULT_SUBSCRIPTION_SUCCESS: {
        draft.isLoading = draft.isLoading - 1;
        draft.defaultSubscriptionDietDetails = action.data;
        break;
      }

      case CLEAR_DEFAULT_SUBSCRIPTION_DETAILS:
        draft.defaultSubscriptionDietDetails = {};
        break;

      case RATE_MENU_SUCCESS:
      case PLAN_MENU_SUCCESS: {
        draft.isLoading = draft.isLoading - 1;
        draft.bag = action.bag;
        draft.calendar.days[
          format(new Date(action.bag.date), 'YYYY-MM-DD')
        ].newState = action.bag.newState;
        break;
      }

      case RESET_LOADING: {
        draft.isLoading = 0;

        break;
      }

      case ADD_LOADING: {
        draft.isLoading = draft.isLoading + action.number;

        break;
      }

      case SET_SELECTED_DIET: {
        draft.selectedDiet = action.selectedDiet;
        draft.selectedDietObject = action.selectedDietObject;
        break;
      }

      case SET_SELECTED_DAY: {
        draft.selectedDay = action.selectedDay;
        break;
      }

      case SET_VISIBLE_CALENDAR_DAYS: {
        draft.visibleCalendarDays = action.visibleCalendarDays;
        break;
      }

      case CLEAR_MEALS: {
        draft.meals = [];
        draft.bag = null;
        break;
      }

      case GET_DIET_DAYS_TO_CHANGE: {
        draft.dietDays = action.dietDays;
        break;
      }

      case SET_DIET_DAYS_TO_CHANGE: {
        draft.dietDaysToChange = action.dietDaysToChange;
        break;
      }

      case SET_INITIAL_DIET_DAYS_TO_CHANGE: {
        draft.initialDietDaysToChange = action.initialDietDaysToChange;
        break;
      }

      case SET_MENU_PLANNING_INITIAL_STATE: {
        return { ...INITIAL_STATE };
      }

      case ADD_ADDONS: {
        const { bagAddons } = action;
        const currentAddonsBagIndex = draft.addons.findIndex(
          ({ bag }) => bag === bagAddons.bag
        );

        if (currentAddonsBagIndex !== -1) {
          draft.addons[currentAddonsBagIndex] = bagAddons;
        } else {
          draft.addons.push(bagAddons);
        }

        break;
      }

      case SET_LAST_CHANGE_IN_DIET_DATA: {
        const { payload } = action;
        draft.tmpChangeData = payload;

        break;
      }

      case SET_UNMOUNTED_PREPARING_MODAL_STATUS: {
        const { isUnmountedPreparingModal } = action;
        draft.isUnmountedPreparingModal = isUnmountedPreparingModal;

        break;
      }

      default:
        return draft;
    }
    return draft;
  });

export default menuPlanningReducer;
