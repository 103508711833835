import React from 'react';
import { withTranslation } from 'react-i18next';
import RenewBadge from 'styledComponents/elements/MenuPlanning/RenewBadge';
import useIsEditable from './MenuPlanningLeftColumn/hooks/useIsEditable';
import PlusIcon from '../icons/PlusIcon';
import CloseIcon from '../icons/CloseIcon';

const DEADLINE_BRAND_KEY = 'changeDeliveryDateDeadLine';
const EDITING_ALLOW_KEY = 'allowChangeDeliveryDate';

const RenewDietBadge = ({ t, onClickAction, suspend }) => {
  const { hasTimePassed } = useIsEditable(
    DEADLINE_BRAND_KEY,
    EDITING_ALLOW_KEY
  );

  const title = suspend
    ? t('$*menuPlaning.details.resumeDayCaption')
    : t('$*menuPlaning.details.holdDayCaption');

  const Icon = () => (suspend ? <PlusIcon /> : <CloseIcon />);

  if (hasTimePassed) return null;

  return (
    <RenewBadge marginLeft={'auto'} marginRight={0} onClick={onClickAction}>
      <Icon />
      <span style={{ marginLeft: '6px', whiteSpace: 'nowrap' }}>{title}</span>
    </RenewBadge>
  );
};

export default withTranslation()(RenewDietBadge);
