import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid';

import StyledCalendar from 'styledComponents/elements/SmallCalendar';

import { compareAsc, format, getISODay } from 'date-fns';
import DayPicker from 'react-day-picker';
import MomentLocalUtils from 'react-day-picker/moment';
import { WEEKDAYS_INDEXES } from 'common/shared/weekdays';
import { mainTheme } from 'themes/main';
import { getWeekFromDateArray } from 'common/services/dateHelpers';
import { uniq } from 'ramda';
import { useTranslation } from 'react-i18next';
import { mapLanguageToDatePickerLocale } from 'common/components/Calendar/services/calendarService';
import { BASE_URL, URLS } from '../../../configuration';

const currentlyAvailableBlocks = (blocks, currentDaysAmount, depth = 0) => {
  if (currentDaysAmount === 0) {
    return [];
  }
  const blocksSum = blocks.reduce((a, b) => a + b, 0);
  const result = [];

  if (currentDaysAmount > 13) {
    return blocks;
  }

  if (currentDaysAmount % blocksSum === 0) {
    return blocks;
  }

  const currentDaysModuloSumPlusSum =
    (currentDaysAmount % blocksSum) + blocksSum;
  if (currentDaysAmount > blocksSum) {
    blocks.forEach((block, index) => {
      if (
        currentDaysModuloSumPlusSum % block === 0 ||
        currentDaysAmount % block === 0
      ) {
        result.push(block);

        if (depth < 5) {
          blocks.forEach(testowany_blok => {
            if (testowany_blok !== block) {
              result.push(
                ...currentlyAvailableBlocks(
                  blocks,
                  currentDaysAmount - block,
                  ++depth
                )
              );
            }
          });
        }
      }
    });
  } else {
    blocks.forEach((block, index) => {
      if (currentDaysAmount % block === 0) {
        result.push(block);
      }
    });
  }
  blocks.forEach((block, index) => {
    blocks.forEach(blockToTest => {
      if (blockToTest !== block) {
        let lastTestedLength;
        let multiplier = 1;
        do {
          const lastTestedLength = currentDaysAmount - multiplier * blockToTest;

          if (lastTestedLength > 0 && lastTestedLength % block === 0) {
            result.push(block);
            result.push(blockToTest);
          }
          multiplier++;
        } while (lastTestedLength > 0);
      }
    });
  });

  return uniq(result);
};

const DeliveryCalendar = ({
  input,
  customDisabledDays,
  mainColor,
  selectedDay,
  dietDays,
  daysConfig,
  values,
  disabledDaysToOrder,
  disabledDaysForOrder,
  ConfigClientPanel,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const changeToDates = input.value;

  const availableDietDays = [...dietDays].sort((a, b) => compareAsc(a, b));
  const formattedAvailableDietDays = availableDietDays.map(date =>
    format(date.date, 'YYYY-MM-DD')
  );

  const blocksFromConfig = arr => {
    let b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    return uniq(b);
  };

  const createDayBlock = day => {
    const clickedIsoDay = getISODay(day);

    const thisWeek = getWeekFromDateArray(day);

    return thisWeek.filter(date => {
      const isoCurrentDay = getISODay(date);
      const clickedWeekdayName = WEEKDAYS_INDEXES[clickedIsoDay];
      const clickedDayConfigValue = daysConfig[clickedWeekdayName];
      const currentWeekdayName = WEEKDAYS_INDEXES[isoCurrentDay];

      return daysConfig[currentWeekdayName] === clickedDayConfigValue;
    });
  };

  const handleChangeToDates = day => {
    let days = changeToDates;

    const dayBlock = createDayBlock(day);

    const formattedDayBlock = dayBlock.map(date => format(date, 'YYYY-MM-DD'));

    if (
      days.some(day => formattedDayBlock.includes(day)) ||
      values.applyForDates.length - values.dates.length <= 0
    ) {
      days = days.filter(
        selectedDay => !formattedDayBlock.includes(selectedDay)
      );
    } else {
      days = [...days, ...formattedDayBlock];
    }

    input.onChange(days);
  };

  if (availableDietDays.length === 0) return null;

  const dietDaysToChangeStyle = `.DayPicker-Day--dietDaysToChange:not(.DayPicker-Day--outside) {
      background-color: ${mainTheme.colorIceBlue2}!important;
      color: ${mainColor}!important;
      border: 0!important;
    }`;

  const chosenDaysStyle = `.DayPicker-Day--chosenDays:not(.DayPicker-Day--outside) {
      background-color: ${mainColor}!important;
      color: ${mainTheme.colorWhite}!important;
      border: 0!important;
      opacity: 0.5!important;
    }`;

  const currentDaysAmount = values.applyForDates.length - values.dates.length;
  const valuesFromConfig = Object.values(daysConfig).map(value => value);
  const blocks = blocksFromConfig(valuesFromConfig);

  const hasAddIcon =
    ConfigClientPanel.calendarIconSubscriptionAddDelivery !== null;

  const { showCalendarIcons } = ConfigClientPanel;

  const PlusIcon =
    hasAddIcon &&
    showCalendarIcons &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${ConfigClientPanel['@resources'][
      ConfigClientPanel.calendarIconSubscriptionAddDelivery
    ]?.contentUrl
    }`;

  return (
    <Flex>
      <Box width="100%">
        <style>
          {dietDaysToChangeStyle}
          {chosenDaysStyle}
        </style>
        <StyledCalendar>
          <DayPicker
            {...input}
            selectedDays={changeToDates.map(day => new Date(day))}
            modifiers={{
              dietDaysToChange: formattedAvailableDietDays.map(
                day => new Date(day)
              ),
              chosenDays: values.applyForDates.map(day => new Date(day)),
            }}
            disabledDays={[
              day => {
                const dayBlock = createDayBlock(day);
                const isDaySelected = changeToDates.includes(
                  format(day, 'YYYY-MM-DD')
                );

                return (
                  formattedAvailableDietDays.includes(
                    format(day, 'YYYY-MM-DD')
                  ) ||
                  (!currentlyAvailableBlocks(
                    blocks,
                    currentDaysAmount
                  ).includes(dayBlock.length) &&
                    !isDaySelected)
                );
              },
              ...disabledDaysForOrder.map(
                day => new Date(day.replace(/ /g, 'T'))
              ),
              ...customDisabledDays,
              ...disabledDaysToOrder,
            ]}
            onDayClick={(day, { selected }) => handleChangeToDates(day)}
            fromMonth={new Date()}
            month={new Date(selectedDay)}
            // months={MONTH_NAMES}
            // weekdaysLong={WEEKDAY_NAMES_LONG}
            // weekdaysShort={WEEKDAY_NAMES_SHORT}
            firstDayOfWeek={1}
            locale={mapLanguageToDatePickerLocale(language)}
            localeUtils={MomentLocalUtils}
          />
        </StyledCalendar>
      </Box>
    </Flex>
  );
};

export default connect(
  ({
    menuPlanning: {
      dietDays,
      selectedDiet,
      dietDaysToChange,
      initialDietDaysToChange,
      selectedDay,
      changeToDates,
    },
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
      brand: {
        mainColor,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    },
    orderForm: {
      orderConfig: { disabledDaysForOrder, disabledDays },
    },
  }) => {
    return {
      ConfigClientPanel,
      mainColor,
      selectedDiet,
      dietDays,
      dietDaysToChange,
      initialDietDaysToChange,
      selectedDay,
      changeToDates,
      disabledDaysToOrder: disabledDays,
      disabledDaysForOrder,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    };
  }
)(DeliveryCalendar);
