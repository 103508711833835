import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

const Calendar = styled.div`
  .DayPicker {
    width: 100%;
    padding: 20px 20px 10px;
    background-color: ${props => props.theme.colorWhite};
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${props => props.theme.baseBorderRadius};

    ${media.mobileDown`
      padding: 20px 10px 10px;
    `};
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        &:hover {
          border-color: ${props => props.theme.baseFontColor};
          background-color: ${props => props.theme.colorWhite};
        }
      }
    }
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Month {
    width: 100%;
    margin: 0;
    border-collapse: separate;
    border-spacing: 22px 24px;

    ${media.largeDown`
      border-spacing: 2px 20px;
    `};
  }

  .DayPicker-Caption {
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.baseFontColor};
    display: none;
  }

  .DayPicker-NavBar {
    position: relative;
  }

  .DayPicker-NavButton {
    top: 1px;
    background: none;
    font-family: 'Font Awesome 5 Free';
    font-weight: ${props => props.theme.fontWeight900};
    color: ${props => props.theme.baseFontColor};
    -webkit-font-smoothing: antialiased;

    &:focus {
      outline: none;
    }
  }

  .DayPicker-NavButton--prev {
    left: calc((100% - 240px) / 2);

    ${media.largeDown`
      left: 0;
    `};

    &:before {
      content: '\f053';
    }
  }

  .DayPicker-NavButton--next {
    right: calc((100% - 240px) / 2);

    ${media.largeDown`
      right: 0;
    `};
    &:before {
      content: '\f054';
    }
  }

  .DayPicker-Weekday {
    padding: 0;
    text-transform: uppercase;
    font-weight: ${props => props.theme.fontWeight900};
    font-size: ${fromPxToRem('12px')};
  }

  .DayPicker-Day {
    padding: 5px 10px;
    border-radius: ${props => props.theme.baseBorderRadius};
    border: 1px solid ${props => props.theme.baseFontColor};
    color: ${props => props.theme.baseFontColor};

    ${media.mobileDown`
      padding: 5px 7px;
    `};

    &:focus {
      outline: none;
    }
  }

  .DayPicker-Day--outside {
    border: 0;
  }

  .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    border: 1px solid ${props => props.theme.colorIceBlue};
    background-color: ${props => props.theme.colorGrey};
    color: ${props => props.theme.colorWhite};
    text-decoration: line-through;
  }

  .DayPicker-Day--today {
    font-weight: ${props => props.theme.fontWeightNormal};
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
    background-color: ${props => props.theme.colorWhite};
    border: 2px solid ${props => props.theme.baseFontColor};
    color: ${props => props.theme.baseFontColor};
    cursor: pointer;
  }

  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      position: relative;
      background-color: ${props => props.theme.baseFontColor};
      border-color: ${props => props.theme.baseFontColor};
      color: ${props => props.theme.colorWhite};

      &:before {
        position: absolute;
        right: -3px;
        bottom: -3px;
        color: ${props => props.theme.colorWhite};
        line-height: 1;
        font-family: 'Font Awesome 5 Free';
        font-weight: ${props => props.theme.fontWeightNormal};
        content: '\f058';
        -webkit-font-smoothing: antialiased;
      }
    }
  }
`;

export default Calendar;
