import React from 'react';
import Modal from '../Modal';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { withTranslation } from 'react-i18next';

const reloadApp = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistration()
      .then(() => {
        console.log('serviceWorker');
        window.location.reload();
      })
      .catch(err => console.log('Could not get registration: ', err));
  }
};

const Index = ({ isOpened, t }) => {
  if (!isOpened) return null;
  return (
    <Modal disableClose hideHeader fullscreen padding="0" widthDesktop="475px">
      <div style={{ textAlign: 'center' }}>
        <p>
          {t(
            '$*common.serviceWorkerUpdate.p1',
            'Cześć! Usprawniliśmy funkcjonowanie panelu klienta 😊!'
          )}
        </p>
        <p>
          {t(
            '$*common.serviceWorkerUpdate.p2',
            'Prosimy o odświeżenie strony lub kliknięcie przycisku Odśwież, aby dokonać aktualizacji.'
          )}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <ButtonPrimary weightBold onClick={reloadApp} sizeMiddle>
            {t('$*common.serviceWorkerUpdate.button', 'Odśwież')}
          </ButtonPrimary>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(Index);
