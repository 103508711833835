import * as Sentry from '@sentry/browser';
import decode from 'jwt-decode';

import { get, post } from 'utils/http';

import { URLS } from 'configuration';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REFRESH_LOGIN_REQUEST,
  REFRESH_LOGIN_SUCCESS,
  REFRESH_LOGIN_FAILURE,
  SWITCH_USER,
  SET_CREDIT_CARDS,
  DELETE_CREDIT_CARD,
  REFRESH_USER_TOKENS,
  SET_ALL_PAYMENT_CARDS,
} from 'views/Auth/actions/const';

import { SET_MENU_PLANNING_INITIAL_STATE } from 'views/MenuPlanning/actions/const';

import { CLEAR_USER_DIET } from 'views/UserDiet/actions/const';

import { translateErrorMessages } from 'common/shared/errorMessages';
import HTTP_CODES from 'common/shared/httpCodes';
import { CLEAR_USER_DATA } from '../../AccountSettings/actions/const';
import { pushAuthenticationPollsterEvent } from 'utils/pollsterTrack';
import { userLogin, pushAuthenticationEvent } from 'utils/gtm';
import { setUserGA } from 'utils/ga';

export const login = (credentials, t) => (dispatch, getState) => {
  const nativeAppConfig = getState().nativeAppConfig;
  dispatch({ type: LOGIN_REQUEST });
  //only facebook authentication contains accessToken key

  let url;

  if (credentials.accessToken) {
    url = URLS.FACEBOOK_LOGIN;
  } else if (credentials.tokenId) {
    url = URLS.GOOGLE_LOGIN;
  } else {
    url = URLS.LOGIN;
  }

  const loginAction = ({ token, refreshToken }) => {
    const user = decode(token);

    if (user) {
      Sentry.configureScope(scope => {
        scope.setUser({ email: user.email });
      });
    }

    if (user && user.id && !nativeAppConfig?.disableTracking) {
      const userId = +user.id.replace('/clients/', '');

      userLogin(userId);
      pushAuthenticationEvent(userId);
      pushAuthenticationPollsterEvent(userId);
      setUserGA(userId);
    }

    return dispatch({
      type: LOGIN_SUCCESS,
      user,
      token,
      refreshToken,
      lastAction: new Date(),
    });
  };

  if (credentials.isAppleAuth) {
    return loginAction({
      token: credentials.token,
      refreshToken: credentials.refreshToken,
    });
  }

  return post(url, credentials)
    .then(({ data: { token, refreshToken } }) =>
      loginAction({ token, refreshToken })
    )
    .catch(error => {
      const errorCode = error.response.status;
      const errorBruteForceTime = error.response.data?.time;

      dispatch({ type: LOGIN_FAILURE });

      if (errorCode === HTTP_CODES.UNAUTHORIZED && errorBruteForceTime) {
        throw translateErrorMessages(t).BRUTE_FORCE(errorBruteForceTime);
      } else if (errorCode === HTTP_CODES.UNAUTHORIZED) {
        throw translateErrorMessages(t).INVALID_CREDENTIALS;
      } else {
        throw error;
      }
    });
};

export const register = registrationData => dispatch => {
  dispatch({ type: REGISTER_REQUEST });

  return post(URLS.REGISTER, registrationData)
    .then(() => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
    })
    .catch(error => {
      const errorCode = error.response.status;

      dispatch({ type: REGISTER_FAILURE });

      const errors = error.response.data.violations.map(({ message }) => {
        return message + '\n';
      });

      throw errorCode === HTTP_CODES.BAD_REQUEST ? errors : error;
    });
};

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_REQUEST });
  localStorage.removeItem('keepMeLoggedIn');

  return get(URLS.LOGOUT)
    .then(() => {
      Sentry.configureScope(scope => {
        scope.setUser({ email: null });
      });

      localStorage.clear();

      dispatch({
        type: CLEAR_USER_DATA,
      });

      dispatch({
        type: SET_MENU_PLANNING_INITIAL_STATE,
      });

      dispatch({
        type: CLEAR_USER_DIET,
      });

      return dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch(error => dispatch({ type: LOGOUT_FAILURE, error }));
};

export const updateUserTokens = tokens => dispatch => {
  dispatch({ type: REFRESH_USER_TOKENS, ...tokens });
};

export const refreshLogin = () => (dispatch, getState) => {
  dispatch({ type: REFRESH_LOGIN_REQUEST });

  const refreshToken = localStorage.getItem('rt');

  return post(URLS.REFRESH_LOGIN, { refreshToken })
    .then(({ data: { token, refreshToken } }) => {
      const user = decode(token);

      return dispatch({
        type: REFRESH_LOGIN_SUCCESS,
        user,
        token,
        refreshToken,
        lastAction: new Date(),
      });
    })
    .catch(error => {
      if (
        error?.response?.status == HTTP_CODES.UNAUTHORIZED ||
        error?.message?.indexOf('401') != -1
      ) {
        dispatch({ type: REFRESH_LOGIN_FAILURE, error });
      }
      throw new Error(error);
    });
};

export const switchUser = userData => dispatch => {
  return dispatch({
    type: SWITCH_USER,
    token: userData.token,
    refreshToken: userData.refreshToken,
    email: userData.email,
    lastAction: new Date(),
  });
};

export const setAllPaymentCards = paymentCards => ({
  type: SET_ALL_PAYMENT_CARDS,
  paymentCards,
});

export const setCreditCards = stripe => ({
  type: SET_CREDIT_CARDS,
  stripe,
});

export const deleteCreditCard = id => ({
  type: DELETE_CREDIT_CARD,
  id,
});
