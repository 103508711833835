export const TOGGLE_PREMIUM = 'menuPlanning/TOGGLE_PREMIUM';
export const TOGGLE_ECOBOX = 'menuPlanning/TOGGLE_ECOBOX';

export const SET_SELECTED_DIET = 'menuPlanning/SET_SELECTED_DIET';
export const SET_SELECTED_DAY = 'menuPlanning/SET_SELECTED_DAY';
export const SET_VISIBLE_CALENDAR_DAYS =
  'menuPlanning/SET_VISIBLE_CALENDAR_DAYS';

export const FETCH_MEALS_REQUEST = 'menuPlanning/FETCH_MEALS_REQUEST';
export const FETCH_MEALS_SUCCESS = 'menuPlanning/FETCH_MEALS_SUCCESS';
export const FETCH_MEALS_FAILURE = 'menuPlanning/FETCH_MEALS_FAILURE';

export const FETCH_DEFAULT_SUBSCRIPTION_REQUEST =
  'menuPlanning/FETCH_DEFAULT_SUBSCRIPTION_REQUEST';
export const FETCH_DEFAULT_SUBSCRIPTION_SUCCESS =
  'menuPlanning/FETCH_DEFAULT_SUBSCRIPTION_SUCCESS';
export const FETCH_DEFAULT_SUBSCRIPTION_FAILURE =
  'menuPlanning/FETCH_DEFAULT_SUBSCRIPTION_FAILURE';

export const CLEAR_DEFAULT_SUBSCRIPTION_DETAILS =
  'menuPlanning/CLEAR_DEFAULT_SUBSCRIPTION_DETAILS';

export const PUT_DEFAULT_SUBSCRIPTION_REQUEST =
  'menuPlanning/PUT_DEFAULT_SUBSCRIPTION_REQUEST';
export const PUT_DEFAULT_SUBSCRIPTION_SUCCESS =
  'menuPlanning/PUT_DEFAULT_SUBSCRIPTION_SUCCESS';
export const PUT_DEFAULT_SUBSCRIPTION_FAILURE =
  'menuPlanning/PUT_DEFAULT_SUBSCRIPTION_FAILURE';

export const FETCH_BAG_REQUEST = 'menuPlanning/FETCH_BAG_REQUEST';
export const FETCH_BAG_SUCCESS = 'menuPlanning/FETCH_BAG_SUCCESS';
export const FETCH_BAG_FAILURE = 'menuPlanning/FETCH_BAG_FAILURE';

export const PLAN_MENU_REQUEST = 'menuPlanning/PLAN_MENU_REQUEST';
export const PLAN_MENU_SUCCESS = 'menuPlanning/PLAN_MENU_SUCCESS';
export const PLAN_MENU_FAILURE = 'menuPlanning/PLAN_MENU_FAILURE';

export const RATE_MENU_REQUEST = 'menuPlanning/RATE_MENU_REQUEST';
export const RATE_MENU_SUCCESS = 'menuPlanning/RATE_MENU_SUCCESS';
export const RATE_MENU_FAILURE = 'menuPlanning/RATE_MENU_FAILURE';

export const CLEAR_MEALS = 'menuPlanning/CLEAR_MEALS';

export const FETCH_USER_DIET_CALENDAR_REQUEST =
  'menuPlanning/FETCH_USER_DIET_CALENDAR_REQUEST';
export const FETCH_USER_DIET_CALENDAR_SUCCESS =
  'menuPlanning/FETCH_USER_DIET_CALENDAR_SUCCESS';
export const FETCH_USER_DIET_CALENDAR_FAILURE =
  'menuPlanning/FETCH_USER_DIET_CALENDAR_FAILURE';

export const GET_DIET_DAYS_TO_CHANGE = 'menuPlanning/GET_DIET_DAYS_TO_CHANGE';
export const SET_DIET_DAYS_TO_CHANGE = 'menuPlanning/SET_DIET_DAYS_TO_CHANGE';
export const SET_INITIAL_DIET_DAYS_TO_CHANGE =
  'menuPlanning/SET_INITIAL_DIET_DAYS_TO_CHANGE';
export const SET_MENU_PLANNING_INITIAL_STATE =
  'menuPlanning/SET_MENU_PLANNING_INITIAL_STATE';

export const ADD_ADDONS = 'menuPlanning/ADD_ADDONS';
export const RESET_LOADING = 'menuPlanning/RESET_LOADING';
export const ADD_LOADING = 'menuPlanning/ADD_LOADING';
export const UPDATE_SELECTED_DAY_DETAILS =
  'menuPlanning/UPDATE_SELECTED_DAY_DETAILS';
export const TOGGLE_SUBSCRIPTION_DELIVERY =
  'menuPlanning/TOGGLE_SUBSCRIPTION_DELIVERY';

export const SET_LAST_CHANGE_IN_DIET_DATA =
  'menuPlanning/SET_LAST_CHANGE_IN_DIET_DATA';
export const SET_UNMOUNTED_PREPARING_MODAL_STATUS =
  'menuPlanning/SET_UNMOUNTED_PREPARING_MODAL_STATUS';
