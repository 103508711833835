import React, { Fragment } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import FORM_FIELD_NAMES from '../../const/formFieldNames';

const PayPoFormContent = ({
  childrenAboveForm = null,
  handleFormSpyChange = () => {},
  isLoadingPreviousPaymentData = false,
}) => {
  const { t } = useTranslation();

  const validators = useFormValidation();
  const supportedRegions = useSelector(
    ({ app: { config } }) => config.multinational.supportedRegions
  );
  const firstSupportedCountry = Object.values(supportedRegions)?.[0];
  const regexpTest = new RegExp(`${firstSupportedCountry.postCodeRegexp}$`);

  return (
    <Fragment>
      <FormSpy
        subscription={{
          valid: true,
          dirty: true,
          values: true,
          initialValues: true,
        }}
        onChange={handleFormSpyChange}
      />
      {childrenAboveForm}
      <Flex
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
      >
        <Box width={[1, 1, 1 / 2]}>
          <Field
            name={FORM_FIELD_NAMES.ADDRESS_CITY}
            type="text"
            component={FormTextInput}
            isBig={true}
            label={t('$*orderSummaryFields.invoiceAddressCity', '$$Miasto: ')}
            isRequired={true}
            validate={validators.required}
            width="100%"
            disabled={isLoadingPreviousPaymentData}
          />
        </Box>
        <Box width={[1, 1, 1 / 2]} ml={['0', '0', '10px']}>
          <Field
            name={FORM_FIELD_NAMES.ADDRESS_STREET}
            type="text"
            component={FormTextInput}
            isBig={true}
            label={t('$*orderSummaryFields.invoiceAddressStreet', '$$Ulica: ')}
            isRequired={true}
            validate={validators.required}
            width="100%"
            disabled={isLoadingPreviousPaymentData}
          />
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
      >
        <Box width={[1, 1, 1 / 3]}>
          <Field
            name={FORM_FIELD_NAMES.ADDRESS_BUILD_NUMBER}
            type="text"
            component={FormTextInput}
            isBig={true}
            label={t(
              '$*orderSummaryFields.invoiceAddressBuildNumber',
              '$$Nr budynku: '
            )}
            isRequired={true}
            validate={validators.required}
            width="100%"
            disabled={isLoadingPreviousPaymentData}
          />
        </Box>
        <Box width={[1, 1, 1 / 3]} ml={['0', '0', '10px']}>
          <Field
            name={FORM_FIELD_NAMES.ADDRESS_PLACE_NUMBER}
            type="text"
            component={FormTextInput}
            isBig={true}
            label={t(
              '$*orderSummaryFields.invoiceAddressPlaceNumber',
              '$$Nr lokalu: '
            )}
            isRequired={false}
            width="100%"
            disabled={isLoadingPreviousPaymentData}
          />
        </Box>
        <Box width={[1, 1, 1 / 3]} ml={['0', '0', '10px']}>
          <Field
            name={FORM_FIELD_NAMES.ADDRESS_POST_CODE}
            type="text"
            component={FormTextInput}
            isBig={true}
            label={t(
              '$*orderSummaryFields.invoiceAddressPostCode',
              '$$Kod pocztowy: '
            )}
            isRequired={true}
            validate={getValidators([
              validators.required,
              value => {
                if (!regexpTest.test(value)) {
                  return t(
                    '$*error.invalidPostcode',
                    '$$Kod pocztowy jest nieprawidłowy'
                  );
                } else {
                  return undefined;
                }
              },
            ])}
            parse={validators.parsePostalCode}
            width="100%"
            disabled={isLoadingPreviousPaymentData}
          />
        </Box>
      </Flex>
    </Fragment>
  );
};

export default PayPoFormContent;
