import {
  TOGGLE_DAY,
  SET_DURATION,
  SET_DAYS_RANGE,
  SET_INCLUDE_SATURDAYS,
  SET_INCLUDE_SUNDAYS,
  CLEAR_SELECTED_DAYS,
  INCREMENT_DAY_CLICKS,
  UNSELECT_SATURDAYS,
  UNSELECT_SUNDAYS,
  SET_SELECTED_DAYS,
} from './const';

import { getDaysConfig } from '../services/calendarService';

export const toggleDay = (day, modifiers, excludedDays) => {
  return (dispatch, getState) => {
    return Promise.resolve(
      dispatch({
        type: TOGGLE_DAY,
        day,
        modifiers,
        excludedDays,
        daysConfig: getDaysConfig(getState),
        paymentMode: getState().dietForm.paymentMode,
      })
    );
  };
};

export const setDuration = duration => ({
  type: SET_DURATION,
  duration,
});

export const setDaysRange = (day, excludedDays, daysConfig = null) => {
  return (dispatch, getState) => {
    return Promise.resolve(
      dispatch({
        type: SET_DAYS_RANGE,
        day,
        excludedDays,
        daysConfig: getDaysConfig(getState),
        paymentMode: getState().dietForm.paymentMode,
      })
    );
  };
};

export const setIncludeSaturdays = includeSaturdays => ({
  type: SET_INCLUDE_SATURDAYS,
  includeSaturdays,
});

export const setIncludeSundays = includeSundays => ({
  type: SET_INCLUDE_SUNDAYS,
  includeSundays,
});

export const unselectSaturdays = () => async dispatch => {
  dispatch({
    type: UNSELECT_SATURDAYS,
  });
};

export const unselectSundays = () => async dispatch => {
  dispatch({
    type: UNSELECT_SUNDAYS,
  });
};

export const clearSelectedDays = () => {
  return dispatch =>
    Promise.resolve(
      dispatch({
        type: CLEAR_SELECTED_DAYS,
      })
    );
};

export const incrementCalendarClicks = () => ({
  type: INCREMENT_DAY_CLICKS,
});

export const setSelectedDays = days => ({
  type: SET_SELECTED_DAYS,
  days,
});
