import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Flex } from '@rebass/grid';
import { pickBy } from 'utils/common';

import Modal from 'common/components/Modal';

import SingleDietCard from 'styledComponents/elements/MenuPlanning/SingleDietCard';
import { Paragraph } from 'styledComponents/elements/Typography';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import Spacer from 'styledComponents/elements/Spacer';
import {
  DisplayOnDesktop,
  DisplayOnMobile,
  media,
} from 'styledComponents/helpers';
import MultipleDaysChangeSection from 'views/MenuPlanning/components/MultipleDaysChangeSection';
import MultipleDaysChangeSectionSubscription from 'views/MenuPlanning/components/MultipleDaysChangeSectionSubscription';
import { sortByPosition } from 'utils/componentHelpers';

import {
  putDefaultSubscriptionConfig,
  unsetAllDaysToChange,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  StyledCheckbox,
  StyledCheckboxOptions,
  StyledCheckboxDescription,
} from '../../../../styledComponents/elements/Form/FormCheckbox';
import styled from 'styled-components';

const StyledParagraph = styled(Paragraph)`
  margin-top: 40px;
  ${media.tabletDown`
      padding-top: 20px;
  `}
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;

class DietChangeModal extends Component {
  state = {
    selectedDietType: null,
    initialDietType: null,
    selectedCalorific: null,
    initialCalorific: null,
    selectedVariant: null,
    initialVariant: null,
    hasChanged: false,
    isSubmitting: false,
    changesForAllSubscriptionsDiets: !!this.props
      .defaultSubscriptionDietSettings,
    useEcoContainers: !!this.props.defaultSubscriptionDietDetails
      ?.useEcoContainers,
    optionChangeMenu: !!this.props.defaultSubscriptionDietDetails
      ?.optionChangeMenu,
  };

  componentDidMount() {
    const {
      diets,
      calorifics,
      variants,
      currentDietId,
      currentCalorificId,
      currentVariantId,
    } = this.props;

    const selectedDietType = diets.find(diet => diet.id === currentDietId);
    const selectedVariant = variants.find(
      variant => variant.id === currentVariantId
    );
    const selectedCalorific = calorifics
      ? calorifics.find(calorific => calorific.id === currentCalorificId)
      : null;
    this.setState({
      selectedDietType,
      selectedCalorific,
      selectedVariant,
      initialDietType: selectedDietType,
      initialVariant: selectedVariant,
      initialCalorific: selectedCalorific,
    });
  }

  onSubmit = form => {
    const { onBeforeSave, dietDaysToChange } = this.props;
    this.setState({
      isSubmitting: true,
    });

    let data = {};
    const changedAttributes = [];

    if (form.selectedDietType) {
      data.diet = form.selectedDietType['@id'];
      changedAttributes.push('diet');
    }
    if (form.selectedVariant) {
      data.variant = form.selectedVariant['@id'];
      changedAttributes.push('variant');
    }
    if (form.selectedCalorific) {
      data.calorific = form.selectedCalorific['@id'];
      changedAttributes.push('calorific');
    }

    data.applyForDates = dietDaysToChange;
    data.changedAttributes = changedAttributes;
    // if request is coming from subscription management window
    if (this.props.defaultSubscriptionDietSettings) {
      if (this.props.ecoContainer) {
        data.useEcoContainers = this.state.useEcoContainers;
      }
      if (this.props.premiumType === 'PREMIUM') {
        data.optionChangeMenu = this.state.optionChangeMenu;
      }
    }

    if (this.state.changesForAllSubscriptionsDiets) {
      return this.props
        .putDefaultSubscriptionConfig(data)
        .then(this.props.toggleModal)
        .catch(error => {
          this.props.onConfirmRequired({ error, payload: data });
        });
    }

    onBeforeSave(data);
  };

  selectDiet = (selectedDietType, values) => {
    this.setState(prevProps => ({
      selectedDietType,
      selectedVariant: this.props.allowChangeVariant
        ? null
        : prevProps.selectedVariant,
      selectedCalorific: this.props.allowChangeCalorific
        ? null
        : prevProps.selectedCalorific,
      hasChanged: true,
    }));
    values.selectedDietType = selectedDietType;
    if (this.props.allowChangeVariant) {
      values.selectedVariant = null;
    }
    if (this.props.allowChangeCalorific) {
      values.selectedCalorific = null;
    }
  };

  selectedCalorific = (selectedCalorific, values) => {
    this.setState({ selectedCalorific, hasChanged: true });
    values.selectedCalorific = selectedCalorific;
  };

  selectVariant = (selectedVariant, values) => {
    this.setState(prevProps => ({
      selectedVariant,
      selectedCalorific: this.props.allowChangeCalorific
        ? null
        : prevProps.selectedCalorific,
      hasChanged: true,
    }));
    values.selectedVariant = selectedVariant;
    if (this.props.allowChangeCalorific) {
      values.selectedCalorific = null;
    }
  };

  stateDiffersFromInitialValues = () => {
    const {
      selectedDietType,
      selectedVariant,
      selectedCalorific,
      initialDietType,
      initialVariant,
      initialCalorific,
      optionChangeMenu,
      useEcoContainers,
      changesForAllSubscriptionsDiets,
    } = this.state;
    const { dietDaysToChange, defaultSubscriptionDietDetails } = this.props;
    if (selectedDietType && selectedVariant && selectedCalorific) {
      if (!initialCalorific || !initialDietType || !initialVariant) {
        return true;
      }

      return (
        selectedDietType['@id'] !== initialDietType['@id'] ||
        selectedVariant['@id'] !== initialVariant['@id'] ||
        selectedCalorific['@id'] !== initialCalorific['@id'] ||
        (dietDaysToChange.length > 0 && !changesForAllSubscriptionsDiets) ||
        !!defaultSubscriptionDietDetails?.optionChangeMenu !==
          optionChangeMenu ||
        !!defaultSubscriptionDietDetails?.useEcoContainers !== useEcoContainers
      );
    } else {
      return false;
    }
  };

  render() {
    const {
      isOpened,
      toggleModal,
      id,
      diets,
      allowChangeDiet,
      allowChangeVariant,
      allowChangeCalorific,
      variants,
      dietsInWhichMealExists,
      needToChangeDietDescription,
      isBagView,
      clearDietsInWhichMealExists,
      defaultSubscriptionDietSettings,
      t,
    } = this.props;
    const {
      selectedDietType,
      selectedCalorific,
      selectedVariant,
      initialDietType,
      initialVariant,
    } = this.state;

    const variantNotSupportedAnymore =
      initialDietType &&
      initialVariant &&
      !initialDietType.variants.some(
        variant => variant['@id'] === initialVariant['@id']
      );

    const selectedVariantNotSupported =
      selectedDietType &&
      selectedVariant &&
      !selectedDietType.variants.some(
        variant => variant['@id'] === selectedVariant['@id']
      );

    const shouldShowWarningMessage = () => {
      return !initialDietType || !initialVariant || variantNotSupportedAnymore;
    };

    const currentCalorifics =
      variants.length && selectedVariant
        ? variants.find(element => element.id === selectedVariant.id).calories
        : [];

    let dietsToShow = this.props.diets;
    // Check if there are diets, that do not include selected variant. If variant change is disabled, exclude these diets
    if (selectedVariant && !allowChangeVariant) {
      dietsToShow = diets.filter(diet =>
        diet.variants.some(variant => variant['@id'] === selectedVariant['@id'])
      );
    }

    const buttonTitle = defaultSubscriptionDietSettings
      ? t('$*common.saveChangesCaption', 'Zapisz zmiany')
      : t('$*dietChangeModal.modifyCaption', '$$Modyfikuj');

    const isPremiumOptionAvailable = this.props.premiumType === 'PREMIUM';

    const hasRequiredDietDescription = !!needToChangeDietDescription;

    const hasRequiredDietTag =
      hasRequiredDietDescription &&
      needToChangeDietDescription.includes('{{required_diet}}');

    const showDietInWhichMealExist =
      !!dietsInWhichMealExists &&
      (hasRequiredDietTag || needToChangeDietDescription === null);

    const [firstParagraph, secondParagraph] = (() => {
      if (hasRequiredDietTag) {
        return needToChangeDietDescription.split('{{required_diet}}');
      } else if (hasRequiredDietDescription) {
        return [needToChangeDietDescription, ''];
      } else {
        const firstParagraph = t(
          '$*dietChangeModal.mealPriceUpgradeWarning',
          '$$Wybrany posiłek, jest dostępny w droższej opcji. Aby móc go wybrać, musisz zmienić rodzaj diety i wariant na jeden z poniższych:'
        );
        return [firstParagraph, ''];
      }
    })();

    return (
      <Modal
        id={id}
        title={t('$*dietChangeModal.title', '$$Modyfikuj dietę')}
        isOpened={isOpened}
        paddingMobile={'0 0 90px 0'}
        withBackButton
        toggleModal={() => {
          this.setState({
            selectedDietType: this.state.initialDietType,
            selectedCalorific: this.state.initialCalorific,
            selectedVariant: this.state.initialVariant,
          });
          clearDietsInWhichMealExists();
          toggleModal();
        }}
        widthDesktop="60%"
        fullscreen
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            selectedDietType: null,
            selectedCalorific: null,
            selectedVariant: null,
          }}
          render={({ handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DescriptionWrapper>
                  <span dangerouslySetInnerHTML={{ __html: firstParagraph }} />
                  <Spacer height="32px" />
                  {showDietInWhichMealExist &&
                    dietsInWhichMealExists?.map(diet => (
                      <div>
                        {diet.existInDiets.map(diet => diet.name).join(', ')} (
                        {diet.variant.name})
                      </div>
                    ))}
                  <Spacer height="32px" />
                  <span dangerouslySetInnerHTML={{ __html: secondParagraph }} />
                  {!!secondParagraph && <Spacer height="32px" />}
                </DescriptionWrapper>

                {shouldShowWarningMessage() && (
                  <Paragraph textAlign={'center'} customPadding={'0 0 20px'}>
                    {t(
                      '$*dietChangeModal.mealVariantUnsupportedWarning',
                      '$$Twój aktualny rodzaj diety lub wariantu nie jest już obsługiwany. Zmian możesz dokonać tylko na inną dietę lub wariant.'
                    )}
                  </Paragraph>
                )}

                <DisplayOnMobile>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    {allowChangeDiet && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietKind',
                            '$$Wybierz rodzaj diety:'
                          )}
                        </Paragraph>
                        {dietsToShow.map(diet => {
                          return (
                            <SingleDietCard
                              key={diet.id}
                              diet={diet}
                              isSelected={
                                selectedDietType &&
                                diet.id === selectedDietType.id
                              }
                              onChange={diet => this.selectDiet(diet, values)}
                            />
                          );
                        })}
                      </Flex>
                    )}

                    {allowChangeVariant && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                        mt={20}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietVariant',
                            '$$Wybierz wariant:'
                          )}
                        </Paragraph>

                        {!selectedDietType && (
                          <Paragraph textAlign={'center'}>
                            {t(
                              '$*dietChangeModal.chooseKindToSeeVariants',
                              '$$Wybierz rodzaj diety, aby zobaczyć dostępne warianty'
                            )}
                          </Paragraph>
                        )}

                        {selectedDietType &&
                          sortByPosition(selectedDietType.variants, true).map(
                            variant => {
                              return (
                                <SingleDietCard
                                  key={variant.id}
                                  diet={variant}
                                  isSelected={
                                    selectedVariant &&
                                    variant.id === selectedVariant.id
                                  }
                                  onChange={variant =>
                                    this.selectVariant(variant, values)
                                  }
                                />
                              );
                            }
                          )}
                      </Flex>
                    )}

                    {allowChangeCalorific && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                        mt={20}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietCalorific',
                            '$$Wybierz kaloryczność:'
                          )}
                        </Paragraph>

                        {(!selectedVariant || selectedVariantNotSupported) && (
                          <Paragraph textAlign={'center'}>
                            {t(
                              '$*dietChangeModal.chooseVariantToSeeCalorifics',
                              '$$Wybierz wariant, aby zobaczyć dostępne kaloryczności'
                            )}
                          </Paragraph>
                        )}

                        {!selectedVariantNotSupported &&
                          currentCalorifics.map(calorific => {
                            return (
                              <SingleDietCard
                                key={calorific.id}
                                diet={calorific}
                                isSelected={
                                  selectedCalorific &&
                                  calorific.id === selectedCalorific.id
                                }
                                onChange={calorific =>
                                  this.selectedCalorific(calorific, values)
                                }
                              />
                            );
                          })}
                      </Flex>
                    )}
                  </Flex>
                </DisplayOnMobile>
                <DisplayOnDesktop>
                  <Flex alignItems="flex-start" justifyContent="center">
                    {allowChangeDiet && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietKind',
                            '$$Wybierz rodzaj diety:'
                          )}
                        </Paragraph>

                        {dietsToShow.map(diet => {
                          return (
                            <SingleDietCard
                              key={diet.id}
                              diet={diet}
                              isSelected={
                                selectedDietType &&
                                diet.id === selectedDietType.id
                              }
                              onChange={diet => this.selectDiet(diet, values)}
                            />
                          );
                        })}
                      </Flex>
                    )}
                    <Spacer height="32px" />
                    {allowChangeVariant && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                        ml={20}
                        mr={20}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietVariant',
                            '$$Wybierz wariant:'
                          )}
                        </Paragraph>

                        {!selectedDietType && (
                          <Paragraph textAlign={'center'}>
                            {t(
                              '$*dietChangeModal.chooseKindToSeeVariants',
                              '$$Wybierz rodzaj diety, aby zobaczyć dostępne warianty'
                            )}
                          </Paragraph>
                        )}

                        {selectedDietType &&
                          sortByPosition(selectedDietType.variants, true).map(
                            variant => {
                              return (
                                <SingleDietCard
                                  key={variant.id}
                                  diet={variant}
                                  isSelected={
                                    selectedVariant &&
                                    variant.id === selectedVariant.id
                                  }
                                  onChange={variant =>
                                    this.selectVariant(variant, values)
                                  }
                                />
                              );
                            }
                          )}
                      </Flex>
                    )}

                    <Spacer height="32px" />
                    {allowChangeCalorific && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        width={'100%'}
                      >
                        <Paragraph isBold noPadding>
                          {t(
                            '$*dietChangeModal.chooseDietCalorific',
                            '$$Wybierz kaloryczność:'
                          )}
                        </Paragraph>

                        {(!selectedVariant || selectedVariantNotSupported) && (
                          <Paragraph textAlign={'center'}>
                            {t(
                              '$*dietChangeModal.chooseVariantToSeeCalorifics',
                              '$$Wybierz wariant, aby zobaczyć dostępne kaloryczności'
                            )}
                          </Paragraph>
                        )}

                        {!selectedVariantNotSupported &&
                          currentCalorifics.map(calorific => {
                            return (
                              <SingleDietCard
                                key={calorific.id}
                                diet={calorific}
                                isSelected={
                                  selectedCalorific &&
                                  calorific.id === selectedCalorific.id
                                }
                                onChange={calorific =>
                                  this.selectedCalorific(calorific, values)
                                }
                              />
                            );
                          })}
                      </Flex>
                    )}
                  </Flex>
                </DisplayOnDesktop>
                <Spacer height={'32px'} />
                <MultipleDaysChangeSection isChangingDiet={true} />
                {defaultSubscriptionDietSettings && (
                  <>
                    {(isPremiumOptionAvailable || this.props.ecoContainer) && (
                      <h2 style={{ fontSize: '24px', marginBottom: '2rem' }}>
                        {t(
                          '$*addonsChangeModal.dietChangeModal.selectAdditionalOptions',
                          'Wybierz dodatkowe opcje'
                        )}
                      </h2>
                    )}
                    {isPremiumOptionAvailable && (
                      <div style={{ display: 'flex', margin: '2rem 0' }}>
                        <StyledCheckboxOptions noMargin>
                          <StyledCheckbox
                            active={!this.state.optionChangeMenu}
                            onClick={() =>
                              this.setState(({ optionChangeMenu }) => ({
                                optionChangeMenu: !optionChangeMenu,
                              }))
                            }
                          >
                            {t('$*checkboxSwitch.no', 'nie')}
                          </StyledCheckbox>
                          <StyledCheckbox
                            active={this.state.optionChangeMenu}
                            onClick={() =>
                              this.setState(({ optionChangeMenu }) => ({
                                optionChangeMenu: !optionChangeMenu,
                              }))
                            }
                          >
                            {t('$*checkboxSwitch.yes', 'tak')}
                          </StyledCheckbox>
                        </StyledCheckboxOptions>
                        <StyledCheckboxDescription
                          onClick={() =>
                            this.setState(({ optionChangeMenu }) => ({
                              optionChangeMenu: !optionChangeMenu,
                            }))
                          }
                        >
                          {t('$*premiumBadge.chooseMenu', 'Wybór menu')}
                        </StyledCheckboxDescription>
                      </div>
                    )}
                    {this.props.ecoContainer && (
                      <div style={{ display: 'flex', margin: '2rem 0' }}>
                        <StyledCheckboxOptions noMargin>
                          <StyledCheckbox
                            active={!this.state.useEcoContainers}
                            onClick={() =>
                              this.setState(({ useEcoContainers }) => ({
                                useEcoContainers: !useEcoContainers,
                              }))
                            }
                          >
                            {t('$*checkboxSwitch.no', 'nie')}
                          </StyledCheckbox>
                          <StyledCheckbox
                            active={this.state.useEcoContainers}
                            onClick={() =>
                              this.setState(({ useEcoContainers }) => ({
                                useEcoContainers: !useEcoContainers,
                              }))
                            }
                          >
                            {t('$*checkboxSwitch.yes', 'tak')}
                          </StyledCheckbox>
                        </StyledCheckboxOptions>
                        <StyledCheckboxDescription
                          onClick={() =>
                            this.setState(({ useEcoContainers }) => ({
                              useEcoContainers: !useEcoContainers,
                            }))
                          }
                        >
                          {t('$*ecoboxActivationModal.title', 'Eko opakowania')}
                        </StyledCheckboxDescription>
                      </div>
                    )}
                    <Spacer
                      style={{ background: '#78818c', marginBottom: '2rem' }}
                      height={'1px'}
                    />
                    <div style={{ display: 'flex' }}>
                      <StyledCheckboxOptions noMargin>
                        <StyledCheckbox
                          active={!this.state.changesForAllSubscriptionsDiets}
                          onClick={() =>
                            this.setState(
                              ({ changesForAllSubscriptionsDiets }) => ({
                                changesForAllSubscriptionsDiets: !changesForAllSubscriptionsDiets,
                              })
                            )
                          }
                        >
                          {t('$*checkboxSwitch.no', 'nie')}
                        </StyledCheckbox>
                        <StyledCheckbox
                          active={this.state.changesForAllSubscriptionsDiets}
                          onClick={() =>
                            this.setState(
                              ({ changesForAllSubscriptionsDiets }) => ({
                                changesForAllSubscriptionsDiets: !changesForAllSubscriptionsDiets,
                              })
                            )
                          }
                        >
                          {t('$*checkboxSwitch.yes', 'tak')}
                        </StyledCheckbox>
                      </StyledCheckboxOptions>
                      <StyledCheckboxDescription
                        onClick={() =>
                          this.setState(
                            ({ changesForAllSubscriptionsDiets }) => ({
                              changesForAllSubscriptionsDiets: !changesForAllSubscriptionsDiets,
                            })
                          )
                        }
                      >
                        {t('$*userDiet.subscription.useForAllDays')}
                      </StyledCheckboxDescription>
                    </div>
                  </>
                )}

                {!isBagView && !this.state.changesForAllSubscriptionsDiets && (
                  <>
                    {defaultSubscriptionDietSettings && (
                      <>
                        <StyledParagraph isBold textAlign="center" size="18px">
                          {t(
                            '$*multipleDaysChangeSection.title',
                            'Wybierz dni do zastosowania:'
                          )}
                        </StyledParagraph>
                        <MultipleDaysChangeSectionSubscription
                          fromDefaultSubscription={
                            defaultSubscriptionDietSettings
                          }
                        />
                      </>
                    )}
                  </>
                )}

                <Flex justifyContent="center" mt={20}>
                  <ButtonPrimary
                    type="submit"
                    uppercased
                    sizeMiddle
                    weightBold
                    isLoading={this.state.isSubmitting}
                    disabled={
                      !selectedDietType ||
                      !selectedCalorific ||
                      !selectedVariant ||
                      !this.stateDiffersFromInitialValues() ||
                      this.state.isSubmitting ||
                      (!this.state.changesForAllSubscriptionsDiets &&
                        defaultSubscriptionDietSettings &&
                        this.props.dietDaysToChange.length === 0) ||
                      !this.stateDiffersFromInitialValues()
                    }
                    fullWidthOnMobile
                  >
                    {this.stateDiffersFromInitialValues()
                      ? buttonTitle
                      : t(
                          '$*dietChangeModal.noChangesCaption',
                          '$$Nie dokonano zmian'
                        )}
                  </ButtonPrimary>
                </Flex>
              </form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    ({
      app: {
        brand: { ecoContainer, premiumType },
        config: {
          modules: {
            ConfigClientPanel: { needToChangeDietDescription },
          },
        },
      },
      menuPlanning: { dietDaysToChange, defaultSubscriptionDietDetails },
    }) => ({
      dietDaysToChange,
      defaultSubscriptionDietDetails,
      ecoContainer,
      premiumType,
      needToChangeDietDescription,
    }),
    {
      unsetAllDaysToChange,
      putDefaultSubscriptionConfig,
    }
  )
)(DietChangeModal);
