import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Modal from 'common/components/Modal';

import { BadgeGreen as StyledBadgeGreen } from '../../../styledComponents/elements/Badge';
import { Paragraph } from '../../../styledComponents/elements/Typography';
import { get, put } from 'utils/http';
import { useTranslation } from 'react-i18next';
import { translatePaymentTypes } from 'utils/componentHelpers';
import { shallowEqual, useSelector } from 'react-redux';
import showToast from 'utils/toast';
import withStripeConsumer from 'common/components/HOC/withStripeConsumer';
import withPaymentCardsConsumer from 'common/components/HOC/withPaymentCardsConsumer';
import SummaryPayPoInfo from 'views/NewOrder/components/summary/SummaryPayPoInfoHistory';
import PaymentCardFormPayU from 'common/components/PaymentCard/PaymentCardFormPayU';

const CustomStyledBadgeGreen = styled(StyledBadgeGreen)`
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  text-align: center;
`;

const PriceToPay = styled.div`
  padding: 15px 0;
  width: 100%;
  font-weight: 900;
`;

const INFO_MODAL_NAME = 'INFO_MODAL';
const ADD_PAYU_CARD_MODAL = 'ADD_PAYU_CARD_MODAL';

const OrderChangePaymentTypeModal = ({
  isOpened,
  toggleModal,
  id,
  orderId,
  priceToPay,
  setPaymentMethod,
  stripe,
  fetchOrders,
  ordersItemsPerPage,
  ordersPage,
  paymentMethodId,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [modalPayload, setModalPayload] = useState(null);
  const { currencySymbol } = useSelector(
    ({
      app: {
        config: {
          multinational: { currencySymbol },
        },
      },
    }) => ({ currencySymbol }),
    shallowEqual
  );
  const [isChangingPaymentType, setIsChangingPaymentType] = useState(false);
  const allPaymentCards = useSelector(({ auth }) => auth.allPaymentCards) ?? [];
  const userHasPayuCard = allPaymentCards.some(
    card => card.provider === 'PAYU_CARD'
  );
  const userHasStripeCard = allPaymentCards.some(
    card => card.provider === 'STRIPE_CARD'
  );

  useEffect(() => {
    if (orderId) {
      setPaymentIsLoading(true);
      get(`/frontend/secure/orders/${orderId}/payment-types`, null, true)
        .then(response => {
          setPaymentMethods(response.data);
        })
        .finally(() => {
          setPaymentIsLoading(false);
        });

      return () => {
        setPaymentMethods([]);
      };
    }
  }, [orderId]);

  useEffect(() => {}, []);

  const availablePaymentMethods = paymentMethods.filter(
    method =>
      !(method.type === 'PAYPO' && (priceToPay > 1000 || priceToPay < 10))
  );

  const handleAfterPaymentTypeChange = async ({ action, payload }) => {
    if (action === 'REDIRECT') {
      return (window.location.href = payload.link);
    }

    if (action === 'MODAL') {
      setModal(INFO_MODAL_NAME);
      setModalPayload(payload);
    }

    if (action === 'CARD_ACTION_REQUIRED') {
      if (payload?.declineCode === 'authentication_required') {
        try {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            payload.clientSecret,
            {
              payment_method: payload.lastPaymentMethodId,
            }
          );

          if (error) {
            showToast({
              message: t(`$*stripe.error.${error.code}`, `$$${error.message}`),
              type: 'error',
            });
          } else {
            if (paymentIntent.status === 'succeeded') {
              showToast({
                message: `${t(
                  '$*stripe.success.payment_intent_authentication_success',
                  '$$Uwierzytelnianie Twojej metody płatności przebiegło pomyślnie!'
                )}
                  ${t(
                    '$*stripe.success.withdrawFunds',
                    '$$Środki zostały pobrane z twojego konta!'
                  )}`,
                type: 'success',
              });

              fetchOrders(true, ordersItemsPerPage, ordersPage);
            }
          }
        } catch (error) {}
      }
    }

    if (action === 'CARD_SUCCESS') {
      showToast({
        message: t(
          '$*stripe.success.cardWithdrawFunds',
          '$$Pomyślnie opłacono zamówienie środkami z twojego konta!'
        ),
        type: 'success',
      });
      fetchOrders(true, ordersItemsPerPage, ordersPage);
    }
  };

  const changePaymentType = paymentType => {
    if (paymentType === 'PAYPO') {
      setPaymentMethod(paymentType);
      setModal(INFO_MODAL_NAME);
      toggleModal();
    } else {
      setIsChangingPaymentType(true);
      setPaymentIsLoading(true);

      put(`/frontend/secure/orders/${orderId}/payment`, {
        paymentType,
      })
        .then(
          ({ data }) => {
            setIsChangingPaymentType(false);
            setPaymentMethod(paymentType);
            handleAfterPaymentTypeChange(data);
          },
          () => {
            if (['STRIPE_CARD', 'PAYU_CARD'].includes(paymentType)) {
              showToast({
                message: t(
                  `$*cardPayment.error.setupCard`,
                  `$$Skonfiguruj kartę w ustawieniach konta!`
                ),
                type: 'error',
              });
            }

            setIsChangingPaymentType(false);
          }
        )
        .finally(() => {
          setPaymentIsLoading(false);
          toggleModal();
        });
    }
  };

  const paymentPayPo = metadata => {
    setIsChangingPaymentType(true);
    setPaymentIsLoading(true);

    put(`/frontend/secure/orders/${orderId}/payment`, {
      paymentType: paymentMethodId,
      metadata,
    })
      .then(
        ({ data }) => {
          setIsChangingPaymentType(false);
          handleAfterPaymentTypeChange(data);
        },
        () => {
          setIsChangingPaymentType(false);
          showToast({
            message: t(
              '$*error.serverErrorOccured',
              '$$Wystąpił błąd serwera, prosimy spróbować ponownie później'
            ),
            type: 'error',
          });
        }
      )
      .finally(() => {
        setPaymentIsLoading(false);
        setModal(null);
      });
  };

  const handleClickPaymentType = type => {
    if (type === 'PAYU_CARD' && !userHasPayuCard) {
      setModal(ADD_PAYU_CARD_MODAL);

      return showToast({
        message: t(
          '$*accountSettings.userPaymentCardForm.addPauCard',
          '$$Dodaj kartę dla płatności PayU'
        ),
        type: 'error',
      });
    }

    if (type === 'STRIPE_CARD' && !userHasStripeCard) {
      return showToast({
        message: t(
          `$*cardPayment.error.setupCard`,
          `$$Skonfiguruj kartę w ustawieniach konta!`
        ),
        type: 'error',
      });
    }

    if (!isChangingPaymentType) {
      return changePaymentType(type);
    }

    return;
  };

  const modalTitleByPaymentType =
    {
      BANK_WIRE: t(
        '$*orderChangePaymentTypeModal.bankWire.modalTitleByPaymentType',
        '$$Płatność przelewem tradycyjnym'
      ),
      CASH: t(
        '$*orderChangePaymentTypeModal.cash.modalTitleByPaymentType',
        '$$Płatność przy odbiorze'
      ),
      PAYPO: t('$*summaryPayPoInfo.title', '$$Dane rozliczeniowe PayPo'),
    }[paymentMethodId] ||
    t('$*orderChangePaymentTypeModal.modalTitleByPaymentType', '$$Płatność');

  return (
    <Fragment>
      <Modal
        id={id}
        title={`${t(
          '$*changePaymentTypeModal.payForOrderTitle',
          'Opłać zamówienie'
        )} #${orderId}`}
        isOpened={isOpened}
        toggleModal={toggleModal}
        contentPadding="15px 30px 0"
      >
        <PriceToPay>
          {t('$*basket.labelToPay', '$$Do zapłaty')}: {priceToPay.toFixed(2)}{' '}
          {currencySymbol}
        </PriceToPay>
        <Paragraph>
          {t('$*common.choosPaymentMethod', 'Wybierz metodę płatności')}:
        </Paragraph>

        {availablePaymentMethods.map(({ '@id': key, type, label }) => (
          <div key={key}>
            <span
              style={{ cursor: 'pointer', marginRight: '5px' }}
              onClick={() => handleClickPaymentType(type)}
            >
              <CustomStyledBadgeGreen>
                {translatePaymentTypes(t)[type] || label}
              </CustomStyledBadgeGreen>
            </span>
          </div>
        ))}

        <div style={{ textAlign: 'center' }}>
          {!paymentIsLoading && (
            <Paragraph
              style={{ cursor: 'pointer' }}
              customPadding="0 20px"
              noMargin
              onClick={toggleModal}
            >
              {t('$*common.cancel', 'Anuluj')}
            </Paragraph>
          )}

          {paymentIsLoading && (
            <Paragraph
              style={{ cursor: 'pointer' }}
              customPadding="0 20px"
              noMargin
            >
              {t('$*common.loadingIsPending', '$$Trwa pobieranie danych...')}
            </Paragraph>
          )}
        </div>
      </Modal>

      <Modal
        id={INFO_MODAL_NAME}
        title={modalTitleByPaymentType}
        isOpened={modal}
        toggleModal={() => setModal(null)}
        contentPadding="15px 30px 0"
        widthDesktop="620px"
        widthMobile="90%"
      >
        {modalPayload && paymentMethodId === 'BANK_WIRE' && (
          <Fragment>
            <Paragraph customPadding="5px 0" isBold={true}>
              {t('$*common.accounNumber', 'Numer rachunku')}:{' '}
              {modalPayload.number}
            </Paragraph>

            <Paragraph customPadding="5px 0">{modalPayload.note}</Paragraph>
          </Fragment>
        )}
        {modalPayload && paymentMethodId === 'CASH' && (
          <Paragraph customPadding="5px 0">{modalPayload.note}</Paragraph>
        )}
        {paymentMethodId === 'PAYPO' && (
          <SummaryPayPoInfo
            paymentIsLoading={paymentIsLoading}
            handlePayPoSubmit={paymentPayPo}
          />
        )}
      </Modal>

      <Modal
        id={ADD_PAYU_CARD_MODAL}
        title={'Dodaj kartę'}
        isOpened={modal}
        toggleModal={() => setModal(null)}
        contentPadding="15px 30px 0"
        widthDesktop="620px"
        widthMobile="90%"
      >
        <PaymentCardFormPayU
          onSuccessAddCard={() => {
            setModal(null);
            showToast({
              message: t(
                '$*accountSettings.userPaymentCardForm.success.addPayuCard',
                '$$Karta została pomyślnie dodana.'
              ),
              type: 'success',
            });
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default withStripeConsumer(
  withPaymentCardsConsumer(withRouter(OrderChangePaymentTypeModal))
);
