import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { format, startOfMonth, isToday, min, max } from 'date-fns';
import { useTranslation, withTranslation } from 'react-i18next';
import { media } from 'styledComponents/helpers';
import produce from 'immer';
import styled from 'styled-components';

import {
  getUserDietCalendar,
  getUserDietsList,
  setSelectedDiet,
} from 'views/UserDiet/actions/userDietActions';

import NOTIFICATIONS from 'common/shared/notifications';
import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';
import Calendar from 'common/components/BigCalendar';
import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';
import { getCalendarDayStatus } from 'common/components/BigCalendar/services/calendarService';

import StyledCalendar from 'styledComponents/elements/BigCalendarModal';
import {
  Button,
  ButtonPrimary,
  ButtonSecondary,
  ButtonPrimaryOutlined,
} from 'styledComponents/elements/Button';

import {
  getBag,
  getDefaultSubscriptionConfig,
  putDefaultSubscriptionConfig,
  clearDefaultSubscriptionConfig,
  setDaysToChange,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import {
  setSelectedDay,
  setVisibleCalendarDays,
} from '../actions/userDietActions';
import {
  getMonthRange,
  getLimitDates,
} from 'views/MenuPlanning/services/calendarService';
import { getBagIdForCurrentDay } from '../services/userDietRepresenter';

import { buildUrl } from 'utils/route';
import { put } from 'utils/http';
import showToast from 'utils/toast';
import { URLS } from 'configuration';
import routeUrls from 'routes/routeUrls';
import DefaultSubscriptionDietDetails from '../components/DefaultSubscriptionDietDetails/index';
import DayPicker from 'react-day-picker';
import { mapLanguageToDatePickerLocale } from '../../../common/components/Calendar/services/calendarService';
import MultipleDaysChangeSectionSubscription from '../../MenuPlanning/components/MultipleDaysChangeSectionSubscription';
import Modal from '../../../common/components/Modal';

import SelectAllIcon from 'views/MenuPlanning/icons/SelectAllIcon';
import UnselectAllIcon from 'views/MenuPlanning/icons/UnselectAllIcon';
import CancelSubscription from 'views/MenuPlanning/icons/CancelSubscription';
import {
  Heading,
  Paragraph,
} from '../../../styledComponents/elements/Typography';
import Spinner from '../../../styledComponents/elements/LoadingSpinner';

const CalendarContainer = styled.div`
  margin-bottom: 32px;
  display: block;

  ${media.tabletUp`
    display: flex;
  `}
`;

const StyledButton = styled(Button)`
  display: block;
  font-size: 1rem;
  color: ${props => props.theme.baseFontColor};
  display: flex;
  align-items: flex-end;
  margin: 2rem 1rem;
`;

const StyledParagraph = styled(Paragraph)`
  ${media.tabletDown`
    margin-top: 20px;
  `}
`;

const DietHoldOnCalendarModalSection = ({
  selectedDay,
  calendarDays,
  setVisibleCalendarDays,
  visibleCalendarDays,
  isLoading,
  userDiets = [],
  selectedDiet,
  selectedDietObject,
  getUserDietCalendar,
  getUserDietsList,
  getDefaultSubscriptionConfig,
  clientCanCancelSubscription,
  setCurrentView,
  dietDaysToChange,
  defaultSubscriptionDietDetails,
  putDefaultSubscriptionConfig,
  setDaysToChange,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [
    initialSuspendCalendarSelectedDays,
    setInitialSuspendCalendarSelectedDays,
  ] = React.useState(null);

  const [isModalOpened, setIsModalOpened] = React.useState('');
  const [isAllDeliveriesToggled, setIsAllDeliveriesToggled] = React.useState(
    defaultSubscriptionDietDetails.active
  );

  useEffect(() => {
    setIsAllDeliveriesToggled(defaultSubscriptionDietDetails.active);
  }, [defaultSubscriptionDietDetails.active]);

  const getInitDaysState = value => {
    const newState =
      {
        SUBSCRIPTION_CONFIGURABLE: DAY_STATUSES.SUBSCRIPTION_ENABLED, //delete
        NOT_DELIVERED_WITH_CONFIGURABLE_ALL: DAY_STATUSES.SUBSCRIPTION_ENABLED,
        NOT_DELIVERED_BLOCKED: DAY_STATUSES.SUBSCRIPTION_ENABLED,
        NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
          DAY_STATUSES.SUBSCRIPTION_ENABLED,
        ADD_DIET: 'NO_DIET', //delete
        NOT_DIET_CAN_PLACE_ORDER: 'NO_DIET',
        SUBSCRIPTION_SUSPENDED: DAY_STATUSES.SUBSCRIPTION_DISABLED,
        CONFIGURABLE: 'NO_DIET',
      }[value.newState] || value.newState;

    return newState;
  };

  const getDaysState = value => {
    if (value['@type'] === 'SubscriptionIntent') {
      return isDietDisabled
        ? DAY_STATUSES.SUBSCRIPTION_DISABLED
        : DAY_STATUSES.SUBSCRIPTION_ENABLED;
    }

    return (
      {
        ADD_DIET: 'NO_DIET',
        CONFIGURABLE: 'NO_DIET',
      }[value.newState] || value.newState
    );
  };

  const replaceDaysState = (days, getState = () => null) => {
    const newDays = Object.entries(days).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...value,
          newState: getState(value),
        },
      }),
      {}
    );

    return newDays;
  };

  const [days, setDays] = useState(
    replaceDaysState(calendarDays, getInitDaysState)
  );

  const checkIfSuspended = day => {
    const { newState } = calendarDays?.[format(day, 'YYYY-MM-DD')] || {};
    switch (newState) {
      case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
        return true;

      default:
        return false;
    }
  };

  const [isDietDisabled, setIsDietDisabled] = useState(false);

  useEffect(() => {
    const isDietDisabled = !selectedDietObject?.subscription?.active;
    setIsDietDisabled(isDietDisabled);
  }, [selectedDietObject?.subscription?.active]);

  useEffect(() => {
    const newDays = replaceDaysState(calendarDays, getInitDaysState);
    setDays({ ...replaceDaysState(newDays, getDaysState), ...days });
    // setDays(calendarDays);
  }, [calendarDays]);

  useLayoutEffect(() => {
    const newDays = replaceDaysState(calendarDays, getDaysState);
    setDays(newDays);
  }, [isDietDisabled]);

  const disabledWeekdaysArray = days
    ? Object.values(days)
        .map(({ newState, date }) =>
          //newState === DAY_STATUSES.NO_DIET ? new Date(date) : false
          newState === DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER ||
          newState === DAY_STATUSES.DISABLED
            ? new Date(date)
            : false
        )
        .filter(Boolean)
    : [];

  const dietsMissing = userDiets.length === 0 && !isLoading;

  const handleMonthChange = month => {
    setVisibleCalendarDays(getMonthRange(startOfMonth(month)));
  };

  const toggleDietDay = date => {
    const bagId = getBagIdForCurrentDay({
      currentDay: date,
      calendarDays: days,
    });
    const dayStatus = getCalendarDayStatus({
      dietsMissing,
      currentDay: date,
      calendarDays: days,
    });

    if (
      dayStatus === DAY_STATUSES.CONFIGURABLE || //delete
      dayStatus === DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL
    ) {
      return history.push(
        buildUrl(routeUrls.MENU_PLANNING, {
          day: dateObjectToYearMonthDay(date),
          bagId,
          dietId: selectedDiet,
        })
      );
    }

    const newDays = produce(days, draftState => {
      const dayKey = format(new Date(date), 'YYYY-MM-DD');
      if (draftState[dayKey]) {
        const item = draftState[dayKey];
        const getState = () => {
          if (item['@type'] === 'SubscriptionIntent') {
            return item.newState === DAY_STATUSES.SUBSCRIPTION_DISABLED ||
              item.newState === DAY_STATUSES.SUBSCRIPTION_SUSPENDED
              ? DAY_STATUSES.SUBSCRIPTION_ENABLED
              : DAY_STATUSES.SUBSCRIPTION_DISABLED;
          }

          return item.newState;
        };

        draftState[dayKey].newState = getState(item);
      }
    });
    setDays(newDays);
  };

  const handleSubmit = async () => {
    const filteredDietDaysToChange = dietDaysToChange.filter(
      day => !isToday(day)
    );

    const enabledPreviouslySuspendedDays = defaultSubscriptionDietDetails.incomingCustomDisabledDates.filter(
      day => !dietDaysToChange.includes(day)
    );

    const suspendedPreviouslyEnabledDays = defaultSubscriptionDietDetails.incomingCustomEnabledDates.filter(
      day => !dietDaysToChange.includes(day)
    );

    const enabled = isAllDeliveriesToggled;
    const disableDays = enabled
      ? filteredDietDaysToChange
      : suspendedPreviouslyEnabledDays;
    const enableDays = enabled
      ? enabledPreviouslySuspendedDays
      : filteredDietDaysToChange;
    const customEnabled = !enabled && filteredDietDaysToChange.length > 0;

    const payload = {
      enabled,
      disableDays,
      enableDays,
      customEnabled,
    };

    await putDefaultSubscriptionConfig(payload);

    const [rangeStart, rangeEnd] = getLimitDates(visibleCalendarDays);
    const dateFrom = format(rangeStart, 'YYYY-MM-DD');
    const dateTo = format(rangeEnd, 'YYYY-MM-DD');

    getUserDietCalendar({
      dietId: selectedDiet,
      dateFrom,
      dateTo,
    });
  };

  if (isLoading > 0) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Box width={1}>
          <Heading textAlign="center">
            <Spinner />
          </Heading>
        </Box>
      </Flex>
    );
  }

  const isSaveButtonDisabled =
    defaultSubscriptionDietDetails.active === isAllDeliveriesToggled &&
    dietDaysToChange?.every(selectedDay =>
      initialSuspendCalendarSelectedDays?.includes(selectedDay)
    ) &&
    dietDaysToChange?.length === initialSuspendCalendarSelectedDays?.length;

  return (
    <Fragment>
      <StyledParagraph
        dangerouslySetInnerHTML={{
          __html: defaultSubscriptionDietDetails.markToRemove
            ? t('$*userDiet.subscription.cancelSubscription.description')
            : t('$*userDiet.subscription.deliveriesChanges.subtitle'),
        }}
        textAlign="center"
        customPadding="0 0 25px"
      />
      <CalendarContainer>
        <Box width={[1, 1, 1, 0.6]}>
          <MultipleDaysChangeSectionSubscription
            fromDefaultSubscription
            isAllDeliveriesToggled={isAllDeliveriesToggled}
            allowUnclickAll
            disableSuspendedDays
            suspendRenew
            currentViewId="default"
            setInitialSuspendCalendarSelectedDays={
              setInitialSuspendCalendarSelectedDays
            }
          />
        </Box>
        <Box style={{ margin: 'auto' }} width={[1, 1, 0.4]}>
          {!defaultSubscriptionDietDetails.markToRemove ? (
            <div style={{ margin: 'auto', width: '280px' }}>
              <StyledButton
                onClick={() => {
                  setDaysToChange([]);
                  setIsAllDeliveriesToggled(!isAllDeliveriesToggled);
                }}
              >
                {isAllDeliveriesToggled ? (
                  <UnselectAllIcon />
                ) : (
                  <SelectAllIcon />
                )}
                <span style={{ marginLeft: '0.5rem' }}>
                  {t(
                    isAllDeliveriesToggled
                      ? '$*userDiet.subscription.unselectDays'
                      : '$*userDiet.subscription.selectAllDays'
                  )}
                </span>
              </StyledButton>
              <div style={{ display: 'inline-block', position: 'relative' }}>
                <ButtonPrimary
                  uppercased
                  sizeMiddle
                  weightBold
                  disabled={isSaveButtonDisabled}
                  withSpinner
                  onClick={handleSubmit}
                  width="250px"
                  style={{ marginLeft: '1rem' }}
                >
                  {t('$*common.saveChangesCaption', 'Zapisz zmiany')}
                </ButtonPrimary>
              </div>

              <p
                style={{
                  fontSize: '0.75rem',
                  marginLeft: '1rem',
                  marginTop: '1rem',
                }}
              >
                *{t('$*userDiet.subscription.selectUnselectDescription')}
              </p>
            </div>
          ) : (
            <ButtonPrimary
              uppercased
              sizeMiddle
              weightBold
              withSpinner
              onClick={() => {
                const payload = {
                  markToRemove: false,
                };
                putDefaultSubscriptionConfig(payload);
              }}
              width="250px"
              style={{ margin: '4rem auto 1rem' }}
            >
              {t('$*userDiet.subscription.resumeSubscription')}
            </ButtonPrimary>
          )}
        </Box>
      </CalendarContainer>
      {!defaultSubscriptionDietDetails.markToRemove && (
        <>
          <DefaultSubscriptionDietDetails setCurrentView={setCurrentView} />

          <Modal
            id={'cancelSubscription'}
            title={t('$*userDiet.subscription.cancelSubscription.prompt')}
            isOpened={isModalOpened}
            paddingMobile={'0 0 90px 0'}
            withBackButton
            toggleModal={() => setIsModalOpened('')}
            widthDesktop="60%"
            fullscreen
          >
            <p>
              {t(
                '$*userDiet.subscription.cancelSubscription.prompt.description1'
              )}
            </p>{' '}
            <p style={{ margin: '4rem 0' }}>
              *
              {t(
                '$*userDiet.subscription.cancelSubscription.prompt.description2'
              )}
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonPrimaryOutlined
                uppercased
                sizeMiddle
                weightBold
                withSpinner
                onClick={() => {
                  setIsModalOpened('');
                }}
                width="250px"
              >
                {t('$*common.no')}
              </ButtonPrimaryOutlined>
              <ButtonPrimary
                uppercased
                sizeMiddle
                weightBold
                withSpinner
                onClick={() => {
                  const payload = {
                    markToRemove: true,
                  };
                  putDefaultSubscriptionConfig(payload);
                  setIsModalOpened('');
                }}
                width="250px"
                style={{ marginLeft: '2rem', marginRight: 0 }}
              >
                {t('$*common.yes')}
              </ButtonPrimary>
            </div>
          </Modal>
          {clientCanCancelSubscription && (
            <StyledButton
              style={{ margin: '4rem auto 1rem' }}
              onClick={() => {
                if (!defaultSubscriptionDietDetails.markToRemove) {
                  setIsModalOpened('cancelSubscription');
                }
              }}
            >
              <CancelSubscription />
              <span style={{ marginLeft: '0.5rem' }}>
                {t('$*userDiet.subscription.cancelSubscription')}
              </span>
            </StyledButton>
          )}
        </>
      )}
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      userDiet: {
        calendar,
        selectedDiet,
        selectedDietObject,
        selectedDay,
        list: userDiets,
      },
      orderForm: {
        orderConfig: { disabledDays },
      },
      menuPlanning: {
        bag,
        dietDaysToChange,
        defaultSubscriptionDietDetails,
        isLoading,
      },
      userDiet: { visibleCalendarDays },
      app: {
        brand: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        config: {
          modules: {
            ConfigClientPanel: { clientCanCancelSubscription },
          },
        },
      },
    }) => ({
      selectedDiet,
      selectedDay,
      dietDaysToChange,
      clientCanCancelSubscription,
      selectedDietObject,
      defaultSubscriptionDietDetails,
      isLoading,
      calendarDays: calendar?.days ?? {},
      bag,
      userDiets,
      disabledDays,
      visibleCalendarDays,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    }),
    {
      getUserDietCalendar,
      getUserDietsList,
      getDefaultSubscriptionConfig,
      getBag,
      setSelectedDiet,
      setSelectedDay,
      setVisibleCalendarDays,
      putDefaultSubscriptionConfig,
      setDaysToChange,
      clearDefaultSubscriptionConfig,
    }
  ),
  withRouter
)(DietHoldOnCalendarModalSection);
