import {
  TOGGLE_PREMIUM,
  TOGGLE_SUBSCRIPTION_DELIVERY,
  FETCH_MEALS_REQUEST,
  FETCH_MEALS_SUCCESS,
  FETCH_MEALS_FAILURE,
  FETCH_BAG_REQUEST,
  FETCH_BAG_SUCCESS,
  FETCH_BAG_FAILURE,
  FETCH_DEFAULT_SUBSCRIPTION_REQUEST,
  FETCH_DEFAULT_SUBSCRIPTION_SUCCESS,
  FETCH_DEFAULT_SUBSCRIPTION_FAILURE,
  PUT_DEFAULT_SUBSCRIPTION_REQUEST,
  PUT_DEFAULT_SUBSCRIPTION_SUCCESS,
  PUT_DEFAULT_SUBSCRIPTION_FAILURE,
  PLAN_MENU_REQUEST,
  PLAN_MENU_SUCCESS,
  PLAN_MENU_FAILURE,
  SET_SELECTED_DIET,
  SET_VISIBLE_CALENDAR_DAYS,
  SET_SELECTED_DAY,
  FETCH_USER_DIET_CALENDAR_FAILURE,
  FETCH_USER_DIET_CALENDAR_REQUEST,
  FETCH_USER_DIET_CALENDAR_SUCCESS,
  CLEAR_MEALS,
  RATE_MENU_REQUEST,
  RATE_MENU_SUCCESS,
  RATE_MENU_FAILURE,
  GET_DIET_DAYS_TO_CHANGE,
  SET_DIET_DAYS_TO_CHANGE,
  SET_INITIAL_DIET_DAYS_TO_CHANGE,
  TOGGLE_ECOBOX,
  ADD_ADDONS,
  RESET_LOADING,
  ADD_LOADING,
  UPDATE_SELECTED_DAY_DETAILS,
  CLEAR_DEFAULT_SUBSCRIPTION_DETAILS,
  SET_LAST_CHANGE_IN_DIET_DATA,
  SET_UNMOUNTED_PREPARING_MODAL_STATUS,
} from './const';

import { get, put, patch } from 'utils/http';
import { buildUrl } from 'utils/route';
import { min, format } from 'date-fns';

import { URLS } from 'configuration';

import {
  formatMealData,
  getMealTypesFromBag,
  getVariantFromBag,
  getMealTypesFromIntentBag,
  getMealTypesFromState,
  prepareMenuPlanRequest,
} from 'views/MenuPlanning/services/menuPlanningConfig';

import { transformUserDietCalendar } from 'views/UserDiet/services/userDietRepresenter';
import {
  transformBag,
  transformBagChangeParams,
} from '../services/menuPlanningRepresenter';
import { prepareMenuRateRequest } from '../services/menuPlanningConfig';

import showToast from '../../../utils/toast';
import NOTIFICATIONS from 'common/shared/notifications';

export const togglePremium = () => dispatch => {
  dispatch({
    type: TOGGLE_PREMIUM,
  });
};

export const toggleEcobox = () => dispatch => {
  dispatch({
    type: TOGGLE_ECOBOX,
  });
};

const convertBagIntent = data => {
  const bag = transformBag(data);
  const newBag = {
    ...bag,
    menuIntents: bag.menuIntents.map(menuIntent => {
      const findSelectedDish = menuIntent.dishes?.find(
        dish => dish['iri'] === menuIntent.selectedDishIri
      );

      return {
        ...menuIntent,
        dish: findSelectedDish ?? null,
      };
    }),
  };

  const bagPayload = {
    bag: newBag,
    variant: getVariantFromBag(data),
    mealTypes: getMealTypesFromIntentBag(data),
    notifications: data.notifications,
  };

  const addons = Object.values(data?.addons)?.reduce(
    (acc, { addon, quantity }) => [...acc, { addon: addon['@id'], quantity }],
    []
  );
  const bagAddons = {
    bag: data['@id'],
    addons,
  };

  return {
    bagPayload,
    bagAddons,
  };
};

export const getIntentBag = ({ dietId, date }) => dispatch => {
  dispatch({
    type: FETCH_BAG_REQUEST,
  });

  return new Promise((resolve, reject) => {
    get(buildUrl(URLS.GET_INTENT_BAG, { dietId, date }))
      .then(({ data }) => {
        const { bagAddons, bagPayload } = convertBagIntent(data);
        dispatch(addBagAddons(bagAddons));

        dispatch({
          type: FETCH_BAG_SUCCESS,
          ...bagPayload,
        });
        resolve(bagPayload);
      })
      .catch(error => {
        dispatch({
          type: FETCH_BAG_FAILURE,
          error,
        });
        reject(error);
      });
  });
};

export const getBag = ({ id }) => dispatch => {
  dispatch({
    type: FETCH_BAG_REQUEST,
  });

  return new Promise((resolve, reject) => {
    get(buildUrl(URLS.GET_BAG, { id }))
      .then(({ data }) => {
        const payload = {
          bag: transformBag(data),
          variant: getVariantFromBag(data),
          mealTypes: getMealTypesFromBag(data),
          notifications: data.notifications,
        };

        const addons = Object.values(data?.addons)?.reduce(
          (acc, { addon, quantity }) => [
            ...acc,
            { addon: addon['@id'], quantity },
          ],
          []
        );
        const bagAddons = {
          bag: data['@id'],
          addons,
        };
        dispatch(addBagAddons(bagAddons));

        dispatch({
          type: FETCH_BAG_SUCCESS,
          ...payload,
        });
        resolve(payload);
      })
      .catch(error => {
        dispatch({
          type: FETCH_BAG_FAILURE,
          error,
        });
        reject(error);
      });
  });
};

export const addMenuIntentsToStore = data => dispatch => {
  const { bagAddons, bagPayload } = convertBagIntent(data);
  dispatch(addBagAddons(bagAddons));

  dispatch({
    type: FETCH_BAG_SUCCESS,
    ...bagPayload,
  });
};

export const clearDefaultSubscriptionConfig = () => dispatch => {
  dispatch({
    type: CLEAR_DEFAULT_SUBSCRIPTION_DETAILS,
  });
};

export const getDefaultSubscriptionConfig = subscriptionId => (
  dispatch,
  getState
) => {
  dispatch({
    type: FETCH_DEFAULT_SUBSCRIPTION_REQUEST,
  });

  return get(buildUrl(URLS.DEFAULT_SUBSCRIPTION_CONFIG, { id: subscriptionId }))
    .then(({ data }) => {
      dispatch({
        type: FETCH_DEFAULT_SUBSCRIPTION_SUCCESS,
        data,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_DEFAULT_SUBSCRIPTION_FAILURE,
        error,
      });
    });
};

export const putDefaultSubscriptionConfig = payload => (dispatch, getState) => {
  dispatch({
    type: PUT_DEFAULT_SUBSCRIPTION_REQUEST,
  });

  const {
    menuPlanning: {
      defaultSubscriptionDietDetails: { id },
    },
    app: {
      config: {
        multinational: { currencySymbol },
      },
    },
  } = getState();

  return put(buildUrl(URLS.DEFAULT_SUBSCRIPTION_CONFIG, { id }), payload)
    .then(({ data }) => {
      dispatch({
        type: PUT_DEFAULT_SUBSCRIPTION_SUCCESS,
        data,
      });

      if (payload.costAcceptKey) {
        showToast({
          message: NOTIFICATIONS(window.t).DATA_UPDATED_SUCCESSFULLY,
          type: 'success',
        });
      } else {
        showToast({
          message: window.t('$*notification.subscriptionUpdatedSucessfully', {
            price: `${data.priceDetails.afterDiscount} ${currencySymbol}`,
          }),
          type: 'success',
        });
      }

      return data;
    })
    .catch(error => {
      dispatch({
        type: PUT_DEFAULT_SUBSCRIPTION_FAILURE,
        error,
      });
      return Promise.reject(error);
    });
};

export const converSubscriptionMenuIntents = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_MEALS_REQUEST,
  });

  const {
    menuPlanning: {
      bag: { menuIntents },
    },
  } = getState();

  const meals = menuIntents.map(({ mealType, dishes, position }) => {
    return {
      ...mealType,
      dishes: {
        position,
        dishes,
      },
    };
  });

  dispatch({
    type: FETCH_MEALS_SUCCESS,
    meals,
  });
};

export const getMeals = ({ id }) => (dispatch, getState) => {
  dispatch({
    type: FETCH_MEALS_REQUEST,
  });

  return get(buildUrl(URLS.CHANGE_MENU_OPTIONS, { id }))
    .then(({ data: { options } }) => {
      const mealTypes = getMealTypesFromState(getState());
      return dispatch({
        type: FETCH_MEALS_SUCCESS,
        meals: formatMealData({
          options,
          mealTypes,
        }),
      });
    })
    .catch(error =>
      dispatch({
        type: FETCH_MEALS_FAILURE,
        error,
      })
    );
};

export const resetLoading = () => ({
  type: RESET_LOADING,
});

export const addLoading = number => ({
  type: ADD_LOADING,
  number,
});

export const getUserDietCalendar = ({
  dietId,
  dateFrom,
  dateTo,
}) => dispatch => {
  dispatch({
    type: FETCH_USER_DIET_CALENDAR_REQUEST,
  });

  return (
    dietId &&
    get(
      buildUrl(URLS.GET_CALENDAR, {
        dietId,
        dateFrom,
        dateTo,
      })
    )
      .then(({ data }) =>
        dispatch({
          type: FETCH_USER_DIET_CALENDAR_SUCCESS,
          calendar: transformUserDietCalendar(data),
        })
      )
      .catch(error =>
        dispatch({
          type: FETCH_USER_DIET_CALENDAR_FAILURE,
          error,
        })
      )
  );
};

export const planMenuIntent = ({ newItems, changedItems, currentItems }) => (
  dispatch,
  getState
) => {
  const {
    menuPlanning: { selectedDiet, selectedDay },
  } = getState();

  dispatch({
    type: PLAN_MENU_REQUEST,
  });

  const selectedMenu = changedItems.reduce((acc, mealTypeIri) => {
    const dishId = newItems[mealTypeIri];

    // change when dishItem has many mealTypes, current we have one mealType for dishItem
    const currentDishItem = currentItems.find(
      ({ mealType }) => mealType['@id'] === mealTypeIri
    );
    const { iri: selectedDishIri } = currentDishItem.dishes.find(
      ({ id }) => id === parseInt(dishId)
    );
    const selectedItem = {
      mealType: currentDishItem.mealType['@id'],
      selectedDishIri,
    };

    return [...acc, selectedItem];
  }, []);

  return put(
    buildUrl(URLS.CHANGE_SUBSCRIPTION_INTENT, { dietId: selectedDiet }),
    { selectedMenu, date: selectedDay }
  )
    .then(({ data }) => {
      const { bagPayload } = convertBagIntent(data);

      dispatch({
        type: PLAN_MENU_SUCCESS,
        bag: bagPayload.bag,
      });
    })
    .catch(error => {
      showToast({
        message: error.response.data['hydra:description'],
        type: 'error',
      });

      return dispatch({
        type: PLAN_MENU_FAILURE,
        error,
      });
    });
};

export const planMenu = ({
  id,
  newItems,
  currentItems,
  changedItems,
}) => dispatch => {
  dispatch({
    type: PLAN_MENU_REQUEST,
  });

  const requestData = prepareMenuPlanRequest({
    currentItems,
    newItems,
    changedItems,
  });

  return put(buildUrl(URLS.PLAN_MENU, { id }), requestData)
    .then(({ data }) => {
      dispatch({
        type: PLAN_MENU_SUCCESS,
        bag: data,
      });
    })
    .catch(error => {
      showToast({
        message: error.response.data['hydra:description'],
        type: 'error',
      });

      return dispatch({
        type: PLAN_MENU_FAILURE,
        error,
      });
    });
};

export const rateMenu = ({ ratedItems, changedFields, bag }) => dispatch => {
  dispatch({
    type: RATE_MENU_REQUEST,
  });

  const requestData = prepareMenuRateRequest({
    ratedItems,
    changedFields,
    bag,
  });

  return put(buildUrl(URLS.RATE_MENU, { id: bag.id }), requestData)
    .then(({ data }) => {
      showToast({
        message: NOTIFICATIONS(window.t).MENU_RATED_SUCCESSFULLY,
        type: 'success',
      });

      dispatch({
        type: RATE_MENU_SUCCESS,
        bag: data,
      });
    })
    .catch(error => {
      const expiredMessage =
        error.response?.data?.violations?.[0]?.propertyPath === 'date'
          ? 'Czas na dostawę miął.'
          : '';

      showToast({
        message: `${
          NOTIFICATIONS(window.t).MENU_RATED_ERROR
        } ${expiredMessage}`,
        type: 'error',
      });

      return dispatch({
        type: RATE_MENU_FAILURE,
        error,
      });
    });
};

export const changeBag = (newParams, bag, additionalParams) => {
  const { id, ...currentParams } = bag;

  return (dispatch, getState) => {
    const {
      userAddresses: { list },
      orderForm: {
        pickupPoints,
        orderConfig: { diets },
      },
    } = getState();

    return put(buildUrl(URLS.CHANGE_BAG, { id }), {
      ...transformBagChangeParams(newParams, currentParams, {
        addresses: list,
        pickupPoints,
        diets,
      }),
      usePointsToPay: false,
      ...additionalParams,
    }).then(
      ({ data }) => {
        dispatch({
          type: SET_LAST_CHANGE_IN_DIET_DATA,
          payload: {
            formParams: newParams,
            bag,
            ...data,
          },
        });

        return data;
      },
      ({ response }) => {
        localStorage.removeItem('dietDayToPay');
        showToast({
          message: response.data.violations
            ? response.data.violations
                .map(error => `${error.propertyPath} - ${error.message}`)
                .join('\n')
            : response.data['hydra:description'],
          type: 'error',
        });
      }
    );
  };
};

export const checkBagNeedsPay = (newParams, { id, ...currentParams }) => {
  return (dispatch, getState) => {
    const {
      userAddresses: { list },
      orderForm: {
        pickupPoints,
        orderConfig: { diets },
      },
    } = getState();

    return patch(
      buildUrl(URLS.CHANGE_NEEDS_PAY, { id }),
      transformBagChangeParams(newParams, currentParams, {
        addresses: list,
        pickupPoints,
        diets,
      })
    ).then(({ data }) => data);
  };
};

export const changeSubscriptionIntent = formData => (dispatch, getState) => {
  const {
    menuPlanning: { selectedDiet, selectedDay },
  } = getState();
  const data = {
    date: format(min(...formData.dates), 'YYYY-MM-DD'),
    ...formData,
  };

  return put(
    buildUrl(URLS.CHANGE_SUBSCRIPTION_INTENT, { dietId: selectedDiet }),
    data
  );
};

export const setSelectedDiet = (selectedDiet, selectedDietObject) => ({
  type: SET_SELECTED_DIET,
  selectedDiet: +selectedDiet,
  selectedDietObject,
});

export const setSelectedDay = (selectedDay, history) => {
  return {
    type: SET_SELECTED_DAY,
    selectedDay,
  };
};

export const setVisibleCalendarDays = visibleCalendarDays => ({
  type: SET_VISIBLE_CALENDAR_DAYS,
  visibleCalendarDays,
});

export const clearMeals = () => ({
  type: CLEAR_MEALS,
});

export const getDietDaysToChange = (dietId, day) => (dispatch, getState) => {
  if (typeof dietId === 'undefined') return;

  get(`/frontend/secure/ecommerce-diets/${dietId}/delivery-days`).then(
    ({ data }) => {
      dispatch({
        type: GET_DIET_DAYS_TO_CHANGE,
        dietDays: data.bags,
      });
    }
  );
};

export const setDaysToChange = dietDaysToChange => ({
  type: SET_DIET_DAYS_TO_CHANGE,
  dietDaysToChange,
});

export const setInitialDaysToChange = initialDietDaysToChange => ({
  type: SET_INITIAL_DIET_DAYS_TO_CHANGE,
  initialDietDaysToChange,
});

export const setAllDaysToChange = days => dispatch => {
  return dispatch({
    type: SET_DIET_DAYS_TO_CHANGE,
    dietDaysToChange: days,
  });
};

export const unsetAllDaysToChange = () => (dispatch, getState) => {
  const initialDietDays = getState().menuPlanning.initialDietDaysToChange;
  return dispatch({
    type: SET_DIET_DAYS_TO_CHANGE,
    dietDaysToChange: initialDietDays,
  });
};

export const addBagAddons = bagAddons => ({
  type: ADD_ADDONS,
  bagAddons,
});

export const toggleSubscriptionDelivery = () => ({
  type: TOGGLE_SUBSCRIPTION_DELIVERY,
});

export const updateSelectedDayDetails = data => ({
  type: UPDATE_SELECTED_DAY_DETAILS,
  data,
});

export const setIsUnmountedPreparingModal = isUnmountedPreparingModal => ({
  type: SET_UNMOUNTED_PREPARING_MODAL_STATUS,
  isUnmountedPreparingModal,
});
