export const CREATE_ORDER_REQUEST = 'orderForm/CREATE_ORDER_REQUEST';

export const CREATE_ORDER_RESPONSE = 'orderForm/CREATE_ORDER_RESPONSE';

export const CREATE_ORDER_SUCCESS = 'orderForm/CREATE_ORDER_SUCCESS';

export const CREATE_ORDER_FAILURE = 'orderForm/CREATE_ORDER_FAILURE';

export const FETCH_ORDER_CONFIG_REQUEST =
  'orderForm/FETCH_ORDER_CONFIG_REQUEST';
export const FETCH_ORDER_CONFIG_SUCCESS =
  'orderForm/FETCH_ORDER_CONFIG_SUCCESS';

export const FETCH_PAYMENT_METHODS = 'orderForm/FETCH_PAYMENT_METHODS';

export const SET_PACKAGE_DESC = 'orderForm/SET_PACKAGE_DESC';
export const SET_DIET_TYPE_DESC = 'orderForm/SET_DIET_TYPE_DESC';
export const SET_CALORIFICS = 'orderForm/SET_CALORIFICS';

export const SET_DELIVERY_PICKUP_POINTS =
  'orderForm/SET_DELIVERY_PICKUP_POINTS';
export const SET_DELIVERY_HOURS = 'orderForm/SET_DELIVERY_HOURS';

export const SET_CALCULATIONS = 'orderForm/SET_CALCULATIONS';
export const SET_DISCOUNT = 'orderForm/SET_DISCOUNT';
export const CLEAR_DISCOUNT = 'orderForm/CLEAR_DISCOUNT';
export const SET_DISCOUNT_FOR_SPECIFIED_DIETS =
  'orderForm/SET_DISCOUNT_FOR_SPECIFIED_DIETS';
export const SET_PAYMENT_METHOD = 'orderForm/SET_PAYMENT_METHOD';
export const SET_ORDER_STATUS = 'orderForm/SET_ORDER_STATUS';
export const SET_USE_MONEY_BOX = 'orderForm/SET_USE_MONEY_BOX';

export const SET_INVOICE_INCLUDED = 'orderForm/SET_INVOICE_INCLUDED';
export const SET_INVOICE_DATA_TYPE = 'orderForm/SET_INVOICE_DATA_TYPE';
export const SET_INVOICE_DATA = 'orderFrom/SET_INVOICE_DATA';

export const RESET_ORDER = 'orderForm/RESET_ORDER';

export const USER_PROCEED = 'orderForm/USER_PROCEED';

export const SET_TEST_MODE = 'orderForm/SET_TEST_MODE';
export const INIT_FORM = 'orderForm/INIT_FORM';
export const UPDATE_FORM = 'orderForm/UPDATE_FORM';
export const UPDATE_FORM_ADDONS = 'orderForm/UPDATE_FORM_ADDONS';
export const SET_ADDRESS_FORM_ID = 'orderForm/SET_ADDRESS_FORM_ID';
export const CLEAR_FORM_FIELDS = 'orderForm/CLEAR_FORM_FIELDS';
export const SET_CURRENT_FORM = 'orderForm/SET_CURRENT_FORM';
export const REMOVE_FORM = 'orderForm/REMOVE_FORM';
export const CLEAR_ALL_FORMS = 'orderForm/CLEAR_ALL_FORMS';
export const SET_DEFAULTS_FROM_URL = 'dietForm/SET_DEFAULTS_FROM_URL';
export const CHANGE_PAYMENT_MODE = 'orderForm/CHANGE_PAYMENT_MODE';
export const SET_PAYMENT_METADATA = 'orderForm/SET_PAYMENT_METADATA';
export const SET_NAV_UPDATES = 'orderForm/SET_NAV_UPDATES';
