import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { Box, Flex } from '@rebass/grid';

import styled from 'styled-components';

import StyledCalendarWithCheckmarksForSubscription from 'styledComponents/elements/SmallCalendarWithCheckmarksForSubscription';
import StyledCalendar from 'styledComponents/elements/SmallCalendarWithoutCheckmarks';
import { Button } from 'styledComponents/elements/Button';

import {
  setAllDaysToChange,
  setDaysToChange,
  unsetAllDaysToChange,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import DayPicker from 'react-day-picker';
import MomentLocalUtils from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';
import { mapLanguageToDatePickerLocale } from 'common/components/Calendar/services/calendarService';
import { getUserDietCalendar } from 'views/UserDiet/actions/userDietActions';
import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';
import { BASE_URL, URLS } from '../../../configuration';
import {
  StyledCheckbox,
  StyledCheckboxDescription,
  StyledCheckboxOptions,
} from '../../../styledComponents/elements/Form/FormCheckbox';

const StyledButton = styled(Button)`
  font-size: 1rem;

  font-weight: bold;
  color: ${props =>
    props.disabled
      ? props.theme.colorLightBlueGrey
      : props.theme.baseFontColor};
`;

const MultipleDaysChangeSectionSubscription = ({
  dietDaysToChange,
  setDaysToChange,
  selectedDay,
  calendar,
  days,
  allowChangeMultipleDays,
  defaultSubscriptionDietDetails,
  allowUnclickAll,
  suspendRenew,
  checkmarks,
  setInitialSuspendCalendarSelectedDays,
  fromDefaultSubscription,
  isAllDeliveriesToggled,
  selectedDiet,
  getUserDietCalendar,
  ConfigClientPanel,
  disableSuspendedDays,
  currentViewId,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(new Date(selectedDay));

  const checkIfAvailable = day => {
    const { newState, ['@type']: type } =
      days?.[format(day, 'YYYY-MM-DD')] || {};
    switch (newState) {
      case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
      case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
        return type === 'SubscriptionIntent';
      case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
        return !!disableSuspendedDays;

      default:
        return false;
    }
  };

  const checkIfBlocked = day => {
    const { newState, ['@type']: type } =
      days?.[format(day, 'YYYY-MM-DD')] || {};

    switch (newState) {
      case DAY_STATUSES.DELIVERED_RATED_BLOCKED:
      case DAY_STATUSES.DELIVERED_RATED_CAN_RATE:
      case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED:
      case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE:
      case DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE:
      case DAY_STATUSES.NOT_DELIVERED_BLOCKED:
        return true;

      default:
        return type === 'Bag';
    }
  };

  useEffect(() => {
    setDaysToChange([selectedDay]);
  }, []);

  useEffect(() => {
    if (
      !isEmpty(defaultSubscriptionDietDetails) &&
      fromDefaultSubscription &&
      currentViewId === 'default'
    ) {
      const suspendedDays =
        defaultSubscriptionDietDetails?.incomingCustomDisabledDates;
      const enabledDays =
        defaultSubscriptionDietDetails?.incomingCustomEnabledDates;

      const daysToChange = defaultSubscriptionDietDetails.active
        ? suspendedDays
        : enabledDays;

      setDaysToChange(daysToChange);
      setInitialSuspendCalendarSelectedDays(daysToChange);
    }
  }, [defaultSubscriptionDietDetails]);

  useEffect(() => {
    if (currentViewId !== 'default' && fromDefaultSubscription) {
      setDaysToChange([]);
    }
  }, [currentViewId]);

  useEffect(() => {
    const dateFrom = startOfMonth(selectedMonth);
    const dateTo = endOfMonth(selectedMonth);

    const payload = {
      dietId: selectedDiet,
      dateFrom: format(dateFrom, 'YYYY-MM-DD'),
      dateTo: format(dateTo, 'YYYY-MM-DD'),
    };

    getUserDietCalendar(payload);
  }, [selectedMonth]);

  const changeDays = day => {
    const formattedDay = format(day, 'YYYY-MM-DD');

    const days = dietDaysToChange.includes(formattedDay)
      ? dietDaysToChange.filter(day => day !== formattedDay)
      : [...dietDaysToChange, formattedDay];

    if (days.length === 0 && !allowUnclickAll) return;

    setDaysToChange(days);
  };
  const isSelectedDietSubscription =
    defaultSubscriptionDietDetails?.['@type'] === 'Subscription';

  const isSubscriptionIntent =
    calendar?.days?.[selectedDay]?.['@type'] === 'SubscriptionIntent' ||
    isSelectedDietSubscription;

  if (
    !isSubscriptionIntent ||
    (!allowChangeMultipleDays && !isSubscriptionIntent) ||
    !days
  )
    return null;

  const daysPossibleToOrderInSelectedMonth = Object.keys(days)
    .filter(day => !dietDaysToChange.includes(day))
    .map(day => new Date(day))
    .filter(checkIfAvailable);

  const selectedDays = isAllDeliveriesToggled
    ? daysPossibleToOrderInSelectedMonth
    : dietDaysToChange.map(day => new Date(day));

  const isMarkedToDelete = defaultSubscriptionDietDetails?.markToRemove;

  const CalendarWrapper = (() => {
    if (suspendRenew) return StyledCalendar;
    return StyledCalendarWithCheckmarksForSubscription;
  })();

  return (
    <Fragment>
      <Flex justifyContent="center">
        {dietDaysToChange.length > 1 && !fromDefaultSubscription && (
          <StyledButton
            onClick={e => {
              e.preventDefault();
              setDaysToChange([selectedDay]);
            }}
          >
            {t('$*menuPlanning.selectOnly', 'Zaznacz tylko')}{' '}
            {format(new Date(selectedDay), 'DD.MM.YYYY')}
          </StyledButton>
        )}
      </Flex>
      <Flex justifyContent="center" alignItems="center" flexWrap="wrap" mb={10}>
        <Flex>
          <Box width="100%" mt={25}>
            <CalendarWrapper>
              <DayPicker
                selectedDays={selectedDays}
                disabledDays={
                  isMarkedToDelete ? () => true : day => !checkIfAvailable(day)
                }
                onDayClick={day => {
                  changeDays(day);
                }}
                fromMonth={new Date()}
                modifiers={{
                  withdiet: checkIfBlocked,
                  filledWithoutCheckmark: checkIfAvailable,
                }}
                onMonthChange={setSelectedMonth}
                month={new Date(selectedDay)}
                firstDayOfWeek={1}
                locale={mapLanguageToDatePickerLocale(language)}
                localeUtils={MomentLocalUtils}
              />
            </CalendarWrapper>
          </Box>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default connect(
  ({
    app: {
      brand: { mainColor, allowChangeMultipleDays },
      config: {
        modules: { ConfigClientPanel },
      },
    },
    menuPlanning: {
      dietDays,
      dietDaysToChange,
      initialDietDaysToChange,
      defaultSubscriptionDietDetails,
      selectedDay,
      calendar,
    },
    userDiet: {
      calendar: { days },
      visibleCalendarDays,
      selectedDiet,
    },
  }) => ({
    ConfigClientPanel,
    allowChangeMultipleDays,
    defaultSubscriptionDietDetails,
    mainColor,
    dietDays,
    dietDaysToChange,
    initialDietDaysToChange,
    selectedDay,
    calendar,
    days,
    visibleCalendarDays,
    selectedDiet,
  }),
  {
    setDaysToChange,
    setAllDaysToChange,
    unsetAllDaysToChange,
    getUserDietCalendar,
  }
)(MultipleDaysChangeSectionSubscription);
