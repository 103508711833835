import FormTextInput from 'common/components/Form/FormTextInput';
import { useSelector } from 'react-redux';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactPhoneNumberAdapter } from 'common/components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';

export const INPUT_IDS = {
  // basic information
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',

  // invoice data
  COMPANY_NAME: 'invoiceCompany',
  NIP: 'invoiceVatNumber',
  ADDRESS_CITY: 'invoiceAddressCity',
  ADDRESS_STREET: 'invoiceAddressStreet',
  ADDRESS_POST_CODE: 'invoiceAddressPostCode',
  ADDRESS_BUILD_NUMBER: 'invoiceAddressBuildNumber',
  ADDRESS_PLACE_NUMBER: 'invoiceAddressPlaceNumber',
  ADDITIONAL_NOTES: 'invoiceComments',
};

export const INITIAL_VALUES = {
  // basic information
  [INPUT_IDS.ID]: '',
  [INPUT_IDS.FIRST_NAME]: '',
  [INPUT_IDS.LAST_NAME]: '',
  [INPUT_IDS.EMAIL]: '',
  [INPUT_IDS.PHONE]: { number: '', countryCode: '' },

  // invoice data
  [INPUT_IDS.COMPANY_NAME]: '',
  [INPUT_IDS.NIP]: '',
  [INPUT_IDS.ADDRESS_POST_CODE]: '',
  [INPUT_IDS.ADDRESS_CITY]: '',
  [INPUT_IDS.ADDRESS_STREET]: '',
  [INPUT_IDS.ADDRESS_BUILD_NUMBER]: '',
  [INPUT_IDS.ADDRESS_PLACE_NUMBER]: '',
  [INPUT_IDS.ADDITIONAL_NOTES]: '',
};

export const useBasicInformationFormInputs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const validators = useFormValidation();

  return useMemo(
    () => [
      {
        id: INPUT_IDS.ID,
        name: INPUT_IDS.ID,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.ID', '$$ID'),
        disabled: true,
        width: '10%',
      },
      {
        id: INPUT_IDS.FIRST_NAME,
        name: INPUT_IDS.FIRST_NAME,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.firstName', '$$Imię'),
        isRequired: true,
        validate: validators.required,
        width: '40%',
      },
      {
        id: INPUT_IDS.LAST_NAME,
        name: INPUT_IDS.LAST_NAME,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.lastName', '$$Nazwisko'),
        isRequired: true,
        validate: validators.required,
        width: '50%',
      },
      {
        id: INPUT_IDS.EMAIL,
        name: INPUT_IDS.EMAIL,
        type: 'email',
        component: FormTextInput,
        label: t('$*accountSettings.basic.email', '$$E-mail'),
        isRequired: true,
        disabled: true,
        validate: getValidators([validators.required, validators.email]),
        width: '50%',
      },
      {
        id: INPUT_IDS.PHONE,
        name: INPUT_IDS.PHONE,
        type: 'text',
        component: ReactPhoneNumberAdapter,
        label: t('$*registerForm.inputs.phone', '$$Telefon'),
        isRequired: true,
        validate: getValidators([
          validators.required,
          validators.requiredLocalizedPhone,
          validators.smartPhoneInRegionValidator,
        ]),
        width: '50%',
        // parse: parsePhoneNumber,
      },
    ],
    [
      t,
      validators.email,
      validators.required,
      validators.requiredLocalizedPhone,
      validators.smartPhoneInRegionValidator,
    ]
  );
};

export const useInvoiceDataFormInputs = () => {
  const supportedRegions = useSelector(
    state => state.app.config.multinational.supportedRegions
  );
  const firstSupportedCountry = Object.values(supportedRegions)?.[0];
  const regexpTest = new RegExp(`${firstSupportedCountry.postCodeRegexp}$`);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const validators = useFormValidation();

  return useMemo(
    () => [
      {
        id: INPUT_IDS.COMPANY_NAME,
        name: INPUT_IDS.COMPANY_NAME,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.invoiceCompany', '$$Nazwa firmy'),
        width: '50%',
      },
      {
        id: INPUT_IDS.NIP,
        name: INPUT_IDS.NIP,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.invoiceVatNumber', '$$NIP'),
        width: '50%',
        maxLength: 10,
        parse: validators.parseNumber,
        validate: getValidators([
          value => (value ? validators.nipValidator(value) : undefined),
        ]),
      },
      {
        id: INPUT_IDS.ADDRESS_CITY,
        name: INPUT_IDS.ADDRESS_CITY,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.invoiceAddressCity', '$$Miasto'),
        width: '50%',
      },
      {
        id: INPUT_IDS.ADDRESS_STREET,
        name: INPUT_IDS.ADDRESS_STREET,
        type: 'text',
        component: FormTextInput,
        label: t('$*accountSettings.basic.invoiceAddressStreet', '$$Ulica'),
        width: '50%',
      },
      {
        id: INPUT_IDS.ADDRESS_POST_CODE,
        name: INPUT_IDS.ADDRESS_POST_CODE,
        type: 'text',
        component: FormTextInput,
        label: t(
          '$*accountSettings.basic.invoiceAddressPostCode',
          '$$Kod pocztowy'
        ),
        width: '33%',
        parse: validators.parsePostalCode,
        validate: getValidators([
          value => {
            if (value) {
              if (!regexpTest.test(value)) {
                return 'Kod pocztowy jest nieprawidłowy';
              } else {
                return undefined;
              }
            }
          },
        ]),
      },
      {
        id: INPUT_IDS.ADDRESS_BUILD_NUMBER,
        name: INPUT_IDS.ADDRESS_BUILD_NUMBER,
        type: 'text',
        component: FormTextInput,
        label: t(
          '$*accountSettings.basic.invoiceAddressBuildNumber',
          '$$Nr budynku'
        ),
        width: '33%',
      },
      {
        id: INPUT_IDS.ADDRESS_PLACE_NUMBER,
        name: INPUT_IDS.ADDRESS_PLACE_NUMBER,
        type: 'text',
        component: FormTextInput,
        label: t(
          '$*accountSettings.basic.invoiceAddressPlaceNumber',
          '$$Nr lokalu'
        ),
        width: '33%',
      },
    ],
    [language, t]
  );
};
