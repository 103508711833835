import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Flex } from '@rebass/grid';
import moment from 'moment';

import Modal from 'common/components/Modal';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { sortByDate, sortByPosition } from 'utils/common';
import { submitOrder } from './duck/actions';
import BasketItem from './BasketItem';

import { Styled } from './Basket.styled';
import { useTranslation } from 'react-i18next';
import FormCheckbox from '../Form/FormCheckbox';

const Basket = () => {
  const basketRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);

  const dispatch = useDispatch();
  const {
    addons,
    multinational,
    isSelectedDietSubscription,
    isSelectedDayIntent,
    allowClientPayWithMoneboxEverywhere,
    moneyBox,
    pointsToCashRate,
  } = useSelector(
    ({
      accountSettings: { userData },
      app: {
        config: { multinational, modules, pointsToCashRate, addons },
      },
      userDiet: { list },
      menuPlanning: {
        selectedDiet,
        selectedDay,
        calendar: { days },
      },
    }) => ({
      addons: addons.reduce((acc, { addons }) => [...acc, ...addons], []),
      multinational,
      isSelectedDietSubscription: !!list?.find(diet => diet.id === selectedDiet)
        ?.subscription,
      isSelectedDayIntent:
        days?.[selectedDay]?.['@type'] === 'SubscriptionIntent',
      moneyBox: userData.moneyBox,
      pointsToCashRate,
      allowClientPayWithMoneboxEverywhere:
        modules.ConfigClientPanel.allowClientPayWithMoneboxEverywhere,
    }),
    shallowEqual
  );

  const { t } = useTranslation();
  const [usePointsToPay, setUsePointsToPay] = useState(false);
  const bags = useSelector(({ basket }) => basket.bags);
  const totalBasketItemCount = useSelector(({ basket }) =>
    basket.bags.reduce((acc, { addons }) => (acc += addons.length), 0)
  );
  const totalBasketPrice = useSelector(({ basket }) =>
    basket.bags.reduce((acc, { addons }) => {
      const bagAddonsPrice = addons.reduce(
        (acc, { quantity, price }) => (acc += quantity * price),
        0
      );

      return (acc += bagAddonsPrice);
    }, 0)
  );

  useEffect(() => {
    const basketDiv = basketRef.current;

    if (totalBasketItemCount > 0 && basketDiv) {
      basketDiv.classList.remove('is-animating');
      setTimeout(() => basketDiv.classList.add('is-animating'), 0);
    }
  }, [totalBasketItemCount]);

  const modalId = 'basket';
  const toggleModal = () => setIsOpen(isOpen === null ? modalId : null);

  const handleSubmit = () => {
    dispatch(submitOrder({ usePointsToPay }));
    toggleModal();
  };

  const getAddonDetail = addonId =>
    addons.find(addon => addon['@id'] === addonId);

  const pointsInCash = moneyBox * pointsToCashRate;
  const restAdditionalPrice = totalBasketPrice - pointsInCash;

  const canUsePaymentPoints =
    allowClientPayWithMoneboxEverywhere &&
    (restAdditionalPrice <= 0 || restAdditionalPrice > 0.5);

  if (isSelectedDietSubscription && isSelectedDayIntent) return null;

  return (
    <Fragment>
      <Styled.Basket ref={basketRef} onClick={() => toggleModal()}>
        <Styled.BasketQuantity>{totalBasketItemCount}</Styled.BasketQuantity>
      </Styled.Basket>
      <Modal
        id={modalId}
        title={t(
          '$*basket.additionalOrderTitle',
          '$$Twoje zamówienie dodatkowe'
        )}
        isOpened={isOpen}
        toggleModal={() => toggleModal()}
        withBackButton
        widthDesktop="620px"
        widthMobile="90%"
        style={{ paddingBottom: 0 }}
      >
        {sortByDate(bags, 'date').map(({ bag, date, addons }) => {
          return (
            <Styled.BagWrapper>
              <Styled.BagWrapper.Header>
                <span>{moment(date).format('dddd DD.MM.YYYY')}</span>
              </Styled.BagWrapper.Header>
              <Styled.BagWrapper.Items>
                {sortByPosition(addons).map(({ addon, quantity, category }) => {
                  const { name, image, clientCost } = getAddonDetail(addon);

                  return (
                    <BasketItem
                      {...{
                        bag,
                        addon,
                        name,
                        category,
                        quantity,
                        image,
                        clientCost,
                        currencySymbol: multinational.currencySymbol,
                      }}
                    />
                  );
                })}
              </Styled.BagWrapper.Items>
            </Styled.BagWrapper>
          );
        })}

        {bags.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            {t(
              '$*basket.emptyBasketTitle',
              '$$Twój koszyk jest pusty, dodaj coś do koszyka.'
            )}
          </div>
        )}
        {bags.length > 0 && (
          <Fragment>
            <Styled.BasketTotalPrice>
              {t('$*basket.totalAmount', '$$Cena całkowita:')}{' '}
              {usePointsToPay ? (
                <Styled.PriceRow>
                  <Styled.OldPrice>
                    {totalBasketPrice.toFixed(2)} {multinational.currencySymbol}
                  </Styled.OldPrice>
                  <Styled.NewPrice>
                    {restAdditionalPrice > 0
                      ? restAdditionalPrice.toFixed(2)
                      : 0}{' '}
                    {multinational.currencySymbol}
                  </Styled.NewPrice>
                </Styled.PriceRow>
              ) : (
                `${totalBasketPrice.toFixed(2)} ${multinational.currencySymbol}`
              )}
            </Styled.BasketTotalPrice>

            {canUsePaymentPoints && moneyBox > 0 && (
              <Styled.BasketUsePointsWrapper>
                <FormCheckbox
                  componentStyle="switch"
                  input={{
                    checked: usePointsToPay,
                    onChange: () => {
                      setUsePointsToPay(!usePointsToPay);
                    },
                  }}
                >
                  {t(
                    '$*common.usePointsToPayOrder',
                    '$$Użyj punktów ze skarbonki'
                  )}
                </FormCheckbox>
              </Styled.BasketUsePointsWrapper>
            )}

            <Flex justifyContent="center" mt={40} onClick={handleSubmit}>
              <ButtonPrimary
                type="submit"
                uppercased
                sizeMiddle
                weightBold
                hasFullWidth
              >
                {t('$*basket.payForOrderCaption', '$$Zamów')}
              </ButtonPrimary>
            </Flex>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

export default Basket;
