import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Flex } from '@rebass/grid';
import { compose } from 'recompose';
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components';

import { ButtonPrimary } from 'styledComponents/elements/Button';

import Modal from 'common/components/Modal';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';

import { getDeliveryPickupPoints } from 'views/NewOrder/actions/orderFormActions';
import { DELIVERY_FIELDS } from 'views/NewOrder/const/formConfig/deliveryFields';
import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';
import DeliveryTakeoutForm from 'views/NewOrder/components/delivery/DeliveryTakeoutForm';
import DeliveryPickupForm from 'views/NewOrder/components/delivery/DeliveryPickupForm';
import MultipleDaysChangeSection from 'views/MenuPlanning/components/MultipleDaysChangeSection';
import MultipleDaysChangeSectionSubscription from 'views/MenuPlanning/components/MultipleDaysChangeSectionSubscription';
import Spacer from 'styledComponents/elements/Spacer';
import { useTranslation } from 'react-i18next';
import DeliveryOptionLabel from 'views/NewOrder/components/delivery/DeliveryOptionLabel';
import { useDeliveryOptions } from 'views/NewOrder/const/formConfig/deliveryOptions';
import {
  StyledCheckbox,
  StyledCheckboxDescription,
  StyledCheckboxOptions,
} from '../../../../styledComponents/elements/Form/FormCheckbox';
import { putDefaultSubscriptionConfig } from '../../actions/menuPlanningActions';
import { Paragraph } from '../../../../styledComponents/elements/Typography';
import { media } from '../../../../styledComponents/helpers';

const DeliveryFormViewWrapper = styled.div`
  margin-top: 50px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 20px;
  padding-bottom: 0;
  ${media.tabletDown`
      padding-top: 20px;
  `}
`;

const getDeliveryFormView = type => {
  switch (type) {
    case '1':
      return DeliveryTakeoutForm;
    case '2':
      return DeliveryPickupForm;
    default:
      return null;
  }
};

const AddressChangeModal = ({
  isOpened,
  toggleModal,
  onBeforeSave,
  id,
  bag,
  deliveryToDoorEnabled,
  defaultSubscriptionDietSettings,
  defaultSubscriptionDietDetails,
  onConfirmRequired,
  putDefaultSubscriptionConfig,
  pickUpPointEnabled,
  dietDaysToChange,
  getDeliveryPickupPoints,
}) => {
  const { t } = useTranslation();
  const [
    changesForAllSubscriptionsDiets,
    setChangesForAllSubscriptionsDiets,
  ] = useState(!!defaultSubscriptionDietSettings);
  const onSubmit = async form => {
    const params =
      form.deliveryType === '1'
        ? { address: form.address }
        : { pickUpPoint: form.pickUpPoint };

    params.applyForDates = dietDaysToChange;
    params.changedAttributes = ['address', 'pickUpPoint'];
    const isAddress = form.deliveryType === '1';
    if (isAddress) {
      params.address = `/addresses/${form.address}`;
      params.pickUpPoint = null;
    } else {
      params.address = null;
      params.pickUpPoint = `/pick-up-points/${form.pickUpPoint}`;
    }

    if (changesForAllSubscriptionsDiets) {
      return putDefaultSubscriptionConfig(params)
        .then(toggleModal)
        .catch(error => {
          if (error.response.status === 422) {
            onConfirmRequired({ error, payload: params });
          }
        });
    }

    onBeforeSave(params);
  };

  const defaultDeliveryType = bag.address ? '1' : '2';

  const [deliveryType, setDeliveryType] = useState(defaultDeliveryType);
  const DeliveryFormView = getDeliveryFormView(deliveryType.toString());

  const deliveryAddressFromBag = bag.address
    ? { address: bag.address['id'].toString() }
    : { pickUpPoint: bag.pickUpPoint['id'].toString() };

  useEffect(() => {
    isOpened === id && getDeliveryPickupPoints();
    if (pickUpPointEnabled && !deliveryToDoorEnabled) {
      setDeliveryType('2');
    }
  }, [isOpened]);

  const DELIVERY_OPTIONS = useDeliveryOptions();
  return (
    <Modal
      id={id}
      title={
        deliveryType === '1'
          ? t('$*addressChangeModal.addresses', 'Adresy')
          : t('$*addressChangeModal.pickupPoints', 'Punkty odbioru')
      }
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      widthDesktop="70%"
      fullscreen
      paddingMobile={'0 0 90px 0'}
      withBackButton
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          deliveryType,
          ...deliveryAddressFromBag,
        }}
        render={({ handleSubmit, values, form, initialValues, ...rest }) => {
          const isButtonDisabled =
            initialValues.deliveryType === values.deliveryType &&
            initialValues.pickUpPoint === values.pickUpPoint &&
            initialValues.address === values.address;

          return (
            <Fragment>
              <form onSubmit={handleSubmit}>
                <Fragment>
                  {deliveryToDoorEnabled && pickUpPointEnabled && (
                    <FormRadioGroup flexDirection="row">
                      {DELIVERY_OPTIONS.map(option => (
                        <Fragment key={option.id}>
                          <Field
                            value={option.id.toString()}
                            customLabel={
                              <DeliveryOptionLabel option={option} />
                            }
                            {...DELIVERY_FIELDS[FORM_FIELD_NAMES.DELIVERY_TYPE]}
                          />

                          <OnChange name={FORM_FIELD_NAMES.DELIVERY_TYPE}>
                            {deliveryTypeId => setDeliveryType(deliveryTypeId)}
                          </OnChange>
                        </Fragment>
                      ))}
                    </FormRadioGroup>
                  )}
                  <DeliveryFormViewWrapper>
                    <DeliveryFormView
                      values={{ ...values, address: {} }}
                      canAddNewAddress
                      isNewOrder={false}
                    />
                  </DeliveryFormViewWrapper>
                  <Spacer height={'30px'} />
                  <MultipleDaysChangeSection />
                  {defaultSubscriptionDietSettings && (
                    <div style={{ display: 'flex' }}>
                      <StyledCheckboxOptions noMargin>
                        <StyledCheckbox
                          active={!changesForAllSubscriptionsDiets}
                          onClick={() =>
                            setChangesForAllSubscriptionsDiets(
                              !changesForAllSubscriptionsDiets
                            )
                          }
                        >
                          {t('$*checkboxSwitch.no', 'nie')}
                        </StyledCheckbox>
                        <StyledCheckbox
                          active={changesForAllSubscriptionsDiets}
                          onClick={() =>
                            setChangesForAllSubscriptionsDiets(
                              !changesForAllSubscriptionsDiets
                            )
                          }
                        >
                          {t('$*checkboxSwitch.yes', 'tak')}
                        </StyledCheckbox>
                      </StyledCheckboxOptions>
                      <StyledCheckboxDescription
                        onClick={() =>
                          setChangesForAllSubscriptionsDiets(
                            !changesForAllSubscriptionsDiets
                          )
                        }
                      >
                        {t('$*userDiet.subscription.useForAllDays')}
                      </StyledCheckboxDescription>
                    </div>
                  )}
                  {!changesForAllSubscriptionsDiets &&
                    defaultSubscriptionDietSettings && (
                      <>
                        <StyledParagraph isBold textAlign="center" size="18px">
                          {t(
                            '$*multipleDaysChangeSection.title',
                            'Wybierz dni do zastosowania:'
                          )}
                        </StyledParagraph>
                        <MultipleDaysChangeSectionSubscription
                          fromDefaultSubscription={
                            defaultSubscriptionDietSettings
                          }
                        />
                      </>
                    )}
                  <Flex justifyContent="center" mt={20}>
                    <ButtonPrimary
                      type="submit"
                      uppercased
                      sizeMiddle
                      weightBold
                      disabled={isButtonDisabled}
                      fullWidthOnMobile
                    >
                      {t('$*addressChangeModal.changeAddress', 'Zmień adres')}
                    </ButtonPrimary>
                  </Flex>
                </Fragment>
              </form>
              <div id="modalAddressUser" />
            </Fragment>
          );
        }}
      />
    </Modal>
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: { deliveryToDoorEnabled, pickUpPointEnabled },
      },
      menuPlanning: { dietDaysToChange, defaultSubscriptionDietDetails },
    }) => ({
      deliveryToDoorEnabled,
      pickUpPointEnabled,
      dietDaysToChange,
      defaultSubscriptionDietDetails,
    }),
    { getDeliveryPickupPoints, putDefaultSubscriptionConfig }
  )
)(AddressChangeModal);
