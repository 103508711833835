import {
  getSelectedDays,
  getSelectedDaysWithoutSaturdays,
  getSelectedDaysWithoutSundays,
} from '../services/calendarService';
import { getSelectedDaysByRange } from 'common/services/calendarService';

import {
  TOGGLE_DAY,
  SET_DURATION,
  SET_DAYS_RANGE,
  SET_INCLUDE_SATURDAYS,
  SET_INCLUDE_SUNDAYS,
  CLEAR_SELECTED_DAYS,
  INCREMENT_DAY_CLICKS,
  UNSELECT_SATURDAYS,
  UNSELECT_SUNDAYS,
  SET_SELECTED_DAYS,
} from '../actions/const';

const INITIAL_STATE = {
  selectedDays: [],
  duration: 0,
  includeSaturdays: true,
  includeSundays: true,
  calendarClicksCounter: 0,
};

const calendarReducer = (
  state = INITIAL_STATE,
  {
    type,
    day,
    modifiers,
    duration,
    includeSaturdays,
    includeSundays,
    days,
    excludedDays,
    daysConfig,
    paymentMode,
  }
) => {
  switch (type) {
    case TOGGLE_DAY:
      return {
        ...state,
        selectedDays:
          paymentMode === '1'
            ? modifiers.selected
              ? []
              : [day]
            : getSelectedDays(
                [...state.selectedDays],
                day,
                modifiers,
                daysConfig,
                excludedDays,
                state.duration,
                state.includeSaturdays,
                state.includeSundays
              ),
      };

    case SET_DURATION:
      return {
        ...state,
        duration,
      };

    case SET_DAYS_RANGE:
      return {
        ...state,
        selectedDays:
          paymentMode === '1'
            ? [day]
            : getSelectedDaysByRange({
                day,
                duration: state.duration,
                includeSaturdays: state.includeSaturdays,
                includeSundays: state.includeSundays,
                excludedDays,
                daysConfig,
              }),
      };

    case SET_INCLUDE_SATURDAYS:
      return {
        ...state,
        includeSaturdays,
      };

    case SET_INCLUDE_SUNDAYS:
      return {
        ...state,
        includeSundays,
      };

    case CLEAR_SELECTED_DAYS:
      return {
        ...state,
        selectedDays: [],
        calendarClicksCounter: 0,
      };

    case INCREMENT_DAY_CLICKS:
      return {
        ...state,
        calendarClicksCounter: state.calendarClicksCounter + 1,
      };

    case UNSELECT_SATURDAYS:
      return {
        ...state,
        selectedDays: getSelectedDaysWithoutSaturdays([...state.selectedDays]),
      };

    case UNSELECT_SUNDAYS:
      return {
        ...state,
        selectedDays: getSelectedDaysWithoutSundays([...state.selectedDays]),
      };

    case SET_SELECTED_DAYS:
      return {
        ...state,
        selectedDays: days,
      };

    default:
      return state;
  }
};

export default calendarReducer;
