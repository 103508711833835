import React, { useEffect } from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateErrorMessages } from 'common/shared/errorMessages';

import URLS from 'routes/routeUrls';
import { buildUrl } from 'utils/route';

import {
  setNextTabDisability,
  setTabsCount,
  setTabIndex,
} from '../components/OrderFormTabs/actions/orderFormTabsActions';
import { createOrder, getOrderCost } from '../actions/orderFormActions';

import { LOGIN_FORM } from 'views/Auth/const/authTabs';
import Auth from 'views/Auth/views/Auth';

import {
  clearUserAddressesFromLS,
  getUserAddressesFromLS,
  getUserAddresses,
  createAddress,
} from 'common/components/UserAddresses/actions/userAddressesActions';
import {
  updateForm,
  setDeliveryAddressFormId,
} from 'views/NewOrder/actions/dietFormActions';

import showToast from 'utils/toast';

import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';

import { withTranslation, useTranslation } from 'react-i18next';

import withStripeConsumer from 'common/components/HOC/withStripeConsumer';
import withPaymentCardsConsumer from 'common/components/HOC/withPaymentCardsConsumer';

import { enhancedEcommerceCheckout } from 'utils/ga';
import { beginCheckoutGTM } from 'utils/gtm';
import ReactPixel from 'react-facebook-pixel';

const OrderFormCustomer = ({
  token,
  paymentMode,
  paymentMethodId,
  onAfterSign,
  setNextTabDisability,
}) => {
  const { t } = useTranslation();
  useCurrentTabNames('OrderFormCustomer');
  useEffect(() => {
    if (
      paymentMode === '1' ||
      paymentMethodId === 'PAYU_CARD' ||
      paymentMethodId === 'STRIPE_CARD'
    ) {
      setNextTabDisability(true);
    }
  }, [paymentMode]);

  if (token) {
    return (
      <div>
        {t(
          '$*newOrder.orderFormCustomer.savingOrder',
          '$$Zapisujemy Twoje zamówienie. Proszę czekać...'
        )}
      </div>
    );
  }

  return <Auth onAfterSign={onAfterSign} selectedForm={LOGIN_FORM} />;
};

export default compose(
  connect(
    ({
      app: {
        config: { modules },
      },
      nativeAppConfig: { disableTracking },
      auth: { token },
      dietForm: { paymentMode },
      orderForm: { paymentMethodId, currentFormId },
    }) => ({
      token,
      paymentMode,
      paymentMethodId,
      modules,
      currentFormId,
      needStripePromise: true,
      disableTracking,
    }),
    {
      setNextTabDisability,
      createOrder,
      clearUserAddressesFromLS,
      setTabsCount,
      setTabIndex,
      createAddress,
      updateForm,
      setDeliveryAddressFormId,
      getOrderCost,
      getUserAddresses,
    }
  ),
  withRouter,
  withTranslation(),
  withStripeConsumer,
  withPaymentCardsConsumer,
  withProps(
    ({
      history,
      createOrder,
      clearUserAddressesFromLS,
      setTabsCount,
      setTabIndex,
      tabsCount,
      paymentMethodId,
      t,
      stripe,
      modules,
      disableTracking,
    }) => ({
      onAfterSign: async () => {
        clearUserAddressesFromLS();

        if (
          paymentMethodId !== 'STRIPE_CARD' &&
          paymentMethodId !== 'PAYU_CARD'
        ) {
          try {
            const { GoogleAnalytics, GoogleTagManager, FacebookPixel } =
              modules || '';
            const { data: order } = await createOrder();

            if (FacebookPixel.enabled && !disableTracking) {
              ReactPixel.track('InitiateCheckout');
            }
            if (GoogleTagManager?.enabled && !disableTracking) {
              beginCheckoutGTM(
                order.id,
                order.priceLeftToPay,
                order.discountCode
              );
            }
            if (GoogleAnalytics?.enabled && !disableTracking) {
              enhancedEcommerceCheckout(
                order.id,
                order.priceLeftToPay,
                order.paymentType,
                order.discountCode
              );
            }

            const orderId =
              order['@type'] === 'PaymentIntent' ? order.order.id : order.id;

            if (
              order['@type'] === 'PaymentIntent' &&
              order?.declineCode === 'authentication_required'
            ) {
              try {
                const {
                  error,
                  paymentIntent,
                } = await stripe.confirmCardPayment(order.clientSecret, {
                  payment_method: order.lastPaymentMethodId,
                });

                if (error) {
                  showToast({
                    message: t(
                      `$*stripe.error.${error.code}`,
                      `$$${error.message}`
                    ),
                    type: 'error',
                  });

                  if (
                    error.decline_code === 'insufficient_funds' ||
                    error?.code === 'payment_intent_authentication_failure'
                  ) {
                    return history.push(URLS.NEW_ORDER_ERROR);
                  } else {
                    return history.push(
                      buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                        orderId,
                      })
                    );
                  }
                } else {
                  if (paymentIntent.status === 'succeeded') {
                    return history.push(
                      buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                        orderId,
                      })
                    );
                  }
                }
              } catch (error) {
                console.log({ error });
              }
            }

            if (order['@type'] === 'PaymentIntent') {
              return history.push(
                buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                  orderId,
                })
              );
            }

            if (order['@type'] === 'Order') {
              order.paymentLink
                ? (window.location.href = order.paymentLink)
                : history.push(
                    buildUrl(URLS.NEW_ORDER_PAYMENT_STATUS, {
                      orderId,
                    })
                  );
            }
          } catch (error) {
            setTabsCount(3);
            setTabIndex(2);

            showToast({
              message:
                error?.response?.data?.violations
                  ?.map(error => error.message)
                  .join('\n') ||
                translateErrorMessages(t).SERVER_ERROR_OCCURRED,
              type: 'error',
            });
          }
        } else {
          setTabIndex(tabsCount - 2);
        }
      },
    })
  )
)(OrderFormCustomer);
