import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isBefore, differenceInMilliseconds } from 'date-fns';

const selector = ({ app: { brand }, menuPlanning: { bag } }) => ({
  brand,
  bag,
});

const useIsEditable = (deadlineBrandKey, editingAllowedKey) => {
  const { brand, bag } = useSelector(selector);
  const [hasTimePassed, setHasTimePassed] = useState(true);
  const isEditingAllowed = brand[editingAllowedKey];

  useEffect(() => {
    const millisecondsLeftToEdit = differenceInMilliseconds(bag[deadlineBrandKey], new Date());
    if (millisecondsLeftToEdit < 1) setHasTimePassed(true);

    const timeoutFunction = setTimeout(
      () => setHasTimePassed(true),
      millisecondsLeftToEdit
    );
    return () => {
      clearTimeout(timeoutFunction);
    };
  }, []);
  return {
    hasTimePassed: isBefore(bag[deadlineBrandKey], new Date()),
    isEditingAllowed
  };
};

export default useIsEditable;
