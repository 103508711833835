import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import StyledSingleMealChooseSection from 'styledComponents/elements/MenuPlanning/SingleMealChooseSection';
import { Paragraph } from 'styledComponents/elements/Typography';
import showToast from 'utils/toast';

import { Field } from 'react-final-form';
import { Box, Flex } from '@rebass/grid';
import Tooltip from 'common/components/Tooltip';
import FormRadio from 'common/components/Form/FormRadio';
import { uniq } from 'ramda';
import { Styled } from './SingleMealChooseSection.styled';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';

const SingleMealChooseSection = ({
  dishes = [],
  selectedDishes = [],
  mealName,
  mealIdString,
  isEditable,
  toggleModal,
  modalId,
  allowChangeDiet,
  premiumType,
  showInformation,
  selectedDay,
  calendar,
  formValues,
  ConfigClientPanel,
}) => {
  const [isExpaneded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const hasDish = () => {
    const dish = selectedDishes.find(
      dish => dish.mealType['@id'] === mealIdString
    );

    return dish.dish;
  };

  const sortedDishes = [...dishes.dishes].sort(
    (dish, nextDish) => Number(nextDish.enabled) - Number(dish.enabled)
  );

  const selectedDish = sortedDishes?.find(
    ({ id }) => id === parseInt(formValues?.[mealIdString])
  );

  const dishImagePlaceholderContentUrl =
    ConfigClientPanel?.['@resources']?.[ConfigClientPanel?.dishImagePlaceholder]
      ?.contentUrl;

  const isSubscriptionSuspended =
    calendar?.days?.[selectedDay]?.newState === 'SUBSCRIPTION_SUSPENDED'; //@todo newState

  return (
    <Fragment>
      <StyledSingleMealChooseSection
        isExpaneded={isExpaneded}
        onClick={() => setIsExpanded(!isExpaneded)}
      >
        <StyledSingleMealChooseSection.Header>
          <StyledSingleMealChooseSection.Title
            textAlignMobile="center"
            widthMobile="100%"
          >
            {mealName}
          </StyledSingleMealChooseSection.Title>
          {isExpaneded ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </StyledSingleMealChooseSection.Header>
        <StyledSingleMealChooseSection.ContentWrapper>
          <StyledSingleMealChooseSection.SelectedDish>
            <Box>{selectedDish?.name}</Box>
          </StyledSingleMealChooseSection.SelectedDish>
        </StyledSingleMealChooseSection.ContentWrapper>
        <Collapse in={isExpaneded} timeout="auto">
          <StyledSingleMealChooseSection.DishWrapper>
            <Styled.DishRadioGroup>
              {dishes.dishes.length === 0 && (
                <Paragraph>
                  {t(
                    '$*singleMealChooseSection.menuNotPlannedYet',
                    'Menu nie zostało jeszcze zaplanowane'
                  )}
                </Paragraph>
              )}

              <Styled.DishWrapper>
                {sortedDishes.map(
                  ({
                    id,
                    calories,
                    fat,
                    name,
                    tags,
                    protein,
                    enabled,
                    ingredients,
                    existInVariants,
                    carbohydrates,
                    rateAVG = '-',
                    servingSuggestion = {},
                    glycemicIndex,
                    image,
                  }) => {
                    const renderDishDetails = () => {
                      const hasImage = showInformation.dishImage;
                      const names = ingredients.map(({ name }) => name);
                      const allergens = ingredients.reduce(
                        (acc, { allergens }) => [...acc, ...allergens],
                        []
                      );

                      return (
                        <Styled.DishBox hasImage={hasImage}>
                          {hasImage && (
                            <Styled.DishImage
                              imageUrl={
                                image?.contentUrl ??
                                dishImagePlaceholderContentUrl
                              }
                            />
                          )}
                          <Styled.DishContent>
                            <Box fontSize={'12px'}>
                              {showInformation.tags && tags.length > 0 && (
                                <Box>
                                  {tags.map(tag => `#${tag.value}`).join(' ')}
                                </Box>
                              )}
                              {showInformation.macroIngredients && (
                                <Box>
                                  {`${t('$*ingredientsShort.protein', 'B')}: ${
                                    protein ? protein.toFixed(2) : 0
                                  } g | ${t(
                                    '$*ingredientsShort.carbohydrates',
                                    'W'
                                  )}: ${
                                    carbohydrates ? carbohydrates.toFixed(2) : 0
                                  } g | ${t('$*ingredientsShort.fats', 'T')}: ${
                                    fat ? fat.toFixed(2) : 0
                                  } g`}
                                </Box>
                              )}
                              {showInformation.dishCalorific && (
                                <Box>
                                  {t(
                                    '$*singleMealChooseSection.calorific',
                                    'Kaloryczność'
                                  )}
                                  : {calories?.toFixed(0) || '-'}{' '}
                                  {t(
                                    '$*singleMealChooseSection.calorificShort',
                                    '$$kcal'
                                  )}
                                </Box>
                              )}
                              {showInformation?.glycemicIndex?.show && (
                                <Flex alignItems="center">
                                  {t(
                                    '$*singleMealChooseSection.glycemicIndex',
                                    'Indeks glikemiczny'
                                  )}
                                  : {glycemicIndex}
                                  {showInformation?.glycemicIndex?.tooltip
                                    .length > 0 && (
                                    <i
                                      className="fas fa-exclamation-circle"
                                      data-for="glycemicIndexTooltip"
                                      data-tip="glycemicIndexTooltip"
                                      style={{
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        paddingRight: '0',
                                      }}
                                    />
                                  )}
                                </Flex>
                              )}
                              {showInformation.avgDishRating && (
                                <Box>
                                  {t(
                                    '$*singleMealChooseSection.rating',
                                    'Ocena'
                                  )}
                                  : {rateAVG?.toFixed(2)}
                                </Box>
                              )}
                              {showInformation.servingProposal && (
                                <Box>
                                  {t(
                                    '$*singleMealChooseSection.servingProposal',
                                    'Propozycja podania'
                                  )}
                                  : {servingSuggestion?.value || '-'}
                                </Box>
                              )}
                              {showInformation.allergens &&
                                allergens.length > 0 && (
                                  <Box>
                                    {t(
                                      '$*singleMealChooseSection.alergens',
                                      'Alergeny'
                                    )}
                                    :{' '}
                                    {uniq(allergens)
                                      .map(allergen => allergen.value)
                                      .join(', ')}
                                  </Box>
                                )}
                              {showInformation.dishComposition &&
                                names.length > 0 && (
                                  <Box>
                                    {t(
                                      '$*singleMealChooseSection.composition',
                                      'Skład dania'
                                    )}
                                    : {uniq(names).join(', ')}
                                  </Box>
                                )}
                            </Box>

                            {showInformation?.glycemicIndex?.tooltip?.length >
                              0 && (
                              <Tooltip
                                id="glycemicIndexTooltip"
                                hide={false}
                                maxWidth="350px"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      showInformation?.glycemicIndex?.tooltip,
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Styled.DishContent>
                        </Styled.DishBox>
                      );
                    };

                    const dishByMealType = selectedDishes.find(
                      dish => dish.mealType['@id'] === mealIdString
                    );

                    const isSelected = id === dishByMealType.dish.id;

                    const FieldComponent = hasDish() && (
                      <Fragment key={id}>
                        <Field
                          value={id.toString()}
                          name={mealIdString}
                          type="radio"
                          component={FormRadio}
                          isSubscriptionSuspended={isSubscriptionSuspended}
                          componentStyle="menu-planning"
                          manualValidation={event => {
                            if (isSubscriptionSuspended) {
                              showToast({
                                message: t(
                                  '$*menuPlanning.toast.renewSubFirst',
                                  'Najpierw należy wznowić dzień subskrypcji'
                                ),
                                type: 'error',
                              });
                              return false;
                            }

                            if (!enabled) {
                              const dishId = event.target.value;
                              if (!dishByMealType.dish) {
                                return null;
                              } else if (dishByMealType.dish.id === dishId) {
                                return false;
                              } else if (!isEditable) {
                                return false;
                              } else {
                                toggleModal(modalId, existInVariants);
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }}
                          label={name}
                          description={renderDishDetails()}
                          hasAutoWidth
                          hasBottomMargin
                          isFaded={
                            isSubscriptionSuspended || (!enabled && !isSelected)
                          }
                        />
                      </Fragment>
                    );

                    if (
                      (premiumType === 'STANDARD' && !allowChangeDiet) ||
                      premiumType === 'DISABLED'
                    ) {
                      return isSelected && FieldComponent;
                    } else {
                      return FieldComponent;
                    }
                  }
                )}
              </Styled.DishWrapper>
            </Styled.DishRadioGroup>
            <StyledSingleMealChooseSection.CollapseButton
              onClick={() => setIsExpanded(!isExpaneded)}
            >
              <i className="fas fa-chevron-up" />
            </StyledSingleMealChooseSection.CollapseButton>
          </StyledSingleMealChooseSection.DishWrapper>
        </Collapse>
      </StyledSingleMealChooseSection>
    </Fragment>
  );
};

export default connect(
  ({
    app: {
      brand: { allowChangeDiet, premiumType, showInformation },
      config: { modules: { ConfigClientPanel } = {} } = {},
    },
    menuPlanning: {
      bag: { state, newState },
      calendar,
      selectedDay,
    },
  }) => ({
    allowChangeDiet,
    premiumType,
    calendar,
    selectedDay,
    state,
    newState,
    showInformation,
    ConfigClientPanel,
  }),
  null
)(SingleMealChooseSection);
